export const tokenReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_TOKEN':
      return action.payload
    case 'UNSET_TOKEN':
      return action.payload
    default:
      return state
  }
}

export const idReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_ID':
      return action.payload
    case 'UNSET_ID':
      return action.payload
    default:
      return state
  }
}

export const nameReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_NAME':
      return action.payload
    case 'UNSET_NAME':
      return action.payload
    default:
      return state
  }
}

export const typeReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_TYPE':
      return action.payload
    case 'UNSET_TYPE':
      return action.payload
    default:
      return state
  }
}

export const countryReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_COUNTRY':
      return action.payload
    case 'UNSET_COUNTRY':
      return action.payload
    default:
      return state
  }
}
export const fieldReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return action.payload
    case 'UNSET_FIELD':
      return action.payload
    default:
      return state
  }
}
export const isLineManagerReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_ISLINEMANAGER':
      return action.payload
    case 'UNSET_ISLINEMANAGER':
      return action.payload
    default:
      return state
  }
}
