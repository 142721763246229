import React from 'react'
import Form from 'react-bootstrap/Form'
import { Dropdown } from 'react-bootstrap'
import { debounce } from 'debounce'
import { useSelector } from 'react-redux'
import { T } from '../Languages/LanguageHandler'

export default function CourseFilter2(props) {
  const dict = T()
  const country = useSelector((state) => state.country)
  const handleSearch = (event) => {
    props.applyNameFilter(event.target.value)
  }

  return (
    <div style={{ width: '100%' }} className='courseFilterResponsive'>
      <Form style={{ width: '60%' }}>
        <Form.Group controlId='courseName'>
          <Form.Control
            onChange={debounce(handleSearch, 1000)}
            placeholder={dict['Search for courses']}
            className='courseFilter courseFilter2'
          />
        </Form.Group>
      </Form>
      <Dropdown className='courseCategoriesButton2'>
        <Dropdown.Toggle
          style={{ maxWidth: '100%' }}
          variant='success'
          id='dropdown-autoclose-outside'
        >
          <span className='CategoriesButton'>{dict['Categories']}</span>{' '}
          <span className='CategoriesButtonArrow'>▼</span>
        </Dropdown.Toggle>

        {country === 'Global' && (
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => props.applyCategoryFilter('All')}>
              <h6>{dict['All']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.applyCategoryFilter('Eyes')}>
              <h6>{dict['Eyes']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.applyCategoryFilter('Skin')}>
              <h6>{dict['Skin']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.applyCategoryFilter('Joints')}>
              <h6>{dict['Joints']}</h6>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.applyCategoryFilter('Aesthetics')}
            >
              <h6>{dict['Aesthetics']}</h6>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.applyCategoryFilter('Regenerative')}
            >
              <h6>{dict['Regenerative']}</h6>
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
        {country === 'Italy' && (
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => props.applyCategoryFilter('All')}>
              <h6>{dict['All']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.applyCategoryFilter('Eyes')}>
              <h6>{dict['Eyes']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.applyCategoryFilter('Skin')}>
              <h6>{dict['Skin']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.applyCategoryFilter('Joints')}>
              <h6>{dict['Joints']}</h6>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.applyCategoryFilter('Aesthetics')}
            >
              <h6>{dict['Aesthetics']}</h6>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.applyCategoryFilter('Regenerative')}
            >
              <h6>{dict['Regenerative']}</h6>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.applyCategoryFilter('Neurosciences')}
            >
              <h6>{dict['Neurosciences']}</h6>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.applyCategoryFilter('Ginecology')}
            >
              <h6>{dict['Ginecology']}</h6>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.applyCategoryFilter('Cardiometabolis')}
            >
              <h6>{dict['Cardiometabolis']}</h6>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                props.applyCategoryFilter('Respiratory & Anti-Infective')
              }
            >
              <h6>{dict['Respiratory & Anti-Infective']}</h6>
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  )
}
