/* eslint-disable */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { backendLink } from '../keys_dev'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'

export default function LineChartComponent(props) {
  let isMounted = true
  const [data, setData] = useState([])
  useEffect(() => {
    axios({
      url: `${backendLink}/api/v1/analytics/getPrePostAnalytics`,
      method: 'POST',
      data: {
        courseId: props.course.courseId,
        userId: props.userData.userId,
      },
    })
      .then((res) => {
        if (isMounted) {
          setData(res.data.data)
        }
      })
      .catch((err) => {
        setData([])
        console.log(err)
      })
    return () => {
      isMounted = false // eslint-disable-line
    }
  }, [])

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            border: '0.1vw solid #003863',
            padding: '0.3vw',
            width: '15vw',
          }}
        >
          <p>{`Section: ${payload[0].payload.section}`} </p>
          <p>{`Question: ${payload[0].payload.question}`}</p>
          <p
            style={{ fontWeight: '700' }}
          >{`${payload[0].payload.questionText}`}</p>
        </div>
      )
    }

    return null
  }

  return (
    <div
      style={{ width: props.width, height: props.height, marginTop: '0.5vw' }}
    >
      <ResponsiveContainer width='100%' height='100%'>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' padding={{ left: 30, right: 30 }} />
          <YAxis />
          {/* <Tooltip /> */}
          <Tooltip content={<CustomTooltip />} />

          <Legend />
          <Line
            type='linear'
            dataKey='preScore'
            stroke='blue'
            activeDot={{ r: 8 }}
            dot={{ stroke: 'blue', strokeWidth: 5 }}
          />
          <Line
            type='linear'
            dataKey='postScore'
            stroke='green'
            dot={{ stroke: 'green', strokeWidth: 5 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
