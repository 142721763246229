import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import CourseCard from '../Components/CourseCard'
import CourseFilter2 from '../Components/CourseFilter2'
import Header from '../Components/Header'
import axios from 'axios'
import { backendLink } from '../keys_dev'
import { useSelector } from 'react-redux'

export default function Courses() {
  const country = useSelector((state) => state.country)
  const field = useSelector((state) => state.field)
  var pathArray = window.location.pathname.split('/')

  const [courses, setCourses] = useState([])
  const [nameFilter, setNameFilter] = useState()
  const [categoryFilter, setCategoryFilter] = useState()
  const [page, setPages] = useState(1) // eslint-disable-line
  const applyNameFilter = (name) => {
    setNameFilter(name)
  }

  const applyCategoryFilter = (category) => {
    if (category === 'All') {
      setCategoryFilter('')
      window.history.pushState('', '', '/courses')
    } else {
      setCategoryFilter(category)
      window.history.pushState('', '', '/courses/' + category)
    }
  }

  useEffect(() => {
    axios({
      url: `${backendLink}/api/v1/course/getCourses`,
      method: 'POST',
      headers: {
        page: page,
        // limit: 12
      },
      data: {
        name: nameFilter,
        category: pathArray[2],
        country: country,
        field: field,
      },
    })
      .then((res) => {
        setCourses(res.data.courses)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [nameFilter, categoryFilter, pathArray[2]]) // eslint-disable-line react-hooks/exhaustive-deps

  function DisplayCourses() {
    if (courses.length !== 0) {
      return courses.map((course, index) => (
        <Col
          style={{
            marginBottom: '5%',
          }}
          xs={12}
          md={6}
          lg={4}
          className='centerContent'
          key={index}
        >
          <CourseCard
            courseViews={course.views}
            courseId={course._id}
            courseField={course.field}
            courseSubField={course.subField}
            courseMinsTaken={course.minsTaken}
            courseName={course.name}
          ></CourseCard>
        </Col>
      ))
    } else {
      return null
    }
  }
  // function DisplayCourses() {
  //   if (courses.length !== 0) {
  //     return courses.map((course, index) =>
  //       index % 3 === 0 ? (
  //         <tr key={index}>
  //           <DisplayCoursesHelper />
  //         </tr>
  //       ) : null
  //     )
  //   } else {
  //     return <tr key='placeholder'></tr>
  //   }
  // }

  // function DisplayCoursesHelper() {
  //   let arr = courses.slice(rowCounter, rowCounter + 3)
  //   rowCounter += 3
  //   return arr.map((course, index) => (
  //     <td style={{ paddingRight: '1.3vw', paddingBottom: '3vw' }} key={index}>
  //       <CourseCard
  //         courseViews={course.views}
  //         courseId={course._id}
  //         courseField={course.field}
  //         courseMinsTaken={course.minsTaken}
  //         courseName={course.name}
  //       ></CourseCard>
  //     </td>
  //   ))
  // }

  return (
    <div>
      <Header></Header>
      <table style={{ width: '100%', marginBottom: '2%' }}>
        <tbody>
          <tr className='CoursesHeaderResponsive'>
            <td>
              <div>
                <CourseFilter2
                  applyNameFilter={applyNameFilter}
                  applyCategoryFilter={applyCategoryFilter}
                ></CourseFilter2>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Row className='ResponsiveCoursesContainer'>
        <DisplayCourses />
      </Row>
    </div>
  )
}
