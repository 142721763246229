import React, { useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
import Button from '@restart/ui/esm/Button'
import { T } from '../Languages/LanguageHandler'

export default function SubmitAnswersPopup(props) {
  const dict = T()
  const [disableButton, setDisableButton] = useState(false)

  function TOF(props) {
    return (
      <div style={{ marginTop: '5%' }}>
        <h2 className='courseInstructorName'>
          {dict['Question']} {props.index + 1}:
        </h2>
        <h4 className='questionText'>{props.question.questionText}</h4>
        <Form style={{ marginTop: '2%', marginLeft: '1%' }}>
          <Form.Check
            checked={props.question.correctAnswer === 'true' ? true : false}
            type='radio'
            key='true'
            label={dict['True']}
            value='true'
            name={props.index}
            id={props.question._id}
            className='questionText h4'
            disabled
          />
          <Form.Check
            checked={props.question.correctAnswer === 'false' ? true : false}
            type='radio'
            label={dict['False']}
            key='false'
            value='false'
            name={props.index}
            id={props.question._id}
            className='questionText h4'
            disabled
          />
        </Form>
      </div>
    )
  }

  function MCQOptions(props) {
    return props.question.mcqOptions
      .split(',')
      .map((option, index) => (
        <Form.Check
          disabled
          checked={props.question.correctAnswer === option ? true : false}
          type='radio'
          label={option}
          name={props.index}
          value={option}
          id={props.question._id}
          className='questionText h4'
          key={index}
        />
      ))
  }

  const handleClose = () => {
    setDisableButton(true)
    props.handlePopupClose()
    setDisableButton(false)
  }

  return (
    <Modal
      show={props.show}
      size='lg'
      centered
      dialogClassName='Popup-modalRadius'
    >
      <Modal.Body>
        <h1
          className='courseAboutTitle display-5'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {dict['Your Score:']}{' '}
        </h1>
        <h1
          className='courseAboutTitle display-5'
          style={{ display: 'flex', color: 'black', justifyContent: 'center' }}
        >
          {props.score}/{props.maxScore}{' '}
        </h1>

        <h1
          className='courseAboutTitle display-5'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {dict['Correct Answers:']}
        </h1>

        {props.questions.map((question, index) =>
          question.type === 'shortQuestion' ? (
            <div key={index} style={{ marginTop: '5%' }}>
              <h2 className='courseInstructorName'>
                {dict['Question']} {index + 1}:
              </h2>
              <h4 className='questionText'>{question.questionText}</h4>
              <Form style={{ marginTop: '2%' }}>
                <Form.Control
                  key={question._id}
                  name={index}
                  className='shortQuestion'
                  value={question.correctAnswer}
                  disabled
                />
              </Form>
            </div>
          ) : question.type === 'mcq' ? (
            <div key={index} style={{ marginTop: '5%' }}>
              <h2 className='courseInstructorName'>
                {dict['Question']} {index + 1}:
              </h2>
              <h4 className='questionText'>{question.questionText}</h4>
              <Form style={{ marginTop: '2%', marginLeft: '1%' }}>
                <MCQOptions question={question} index={index}></MCQOptions>
              </Form>
            </div>
          ) : (
            <TOF key={index} question={question} index={index} />
          )
        )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={handleClose}
            className='CourseSubmitAnswersButton'
            disabled={disableButton}
          >
            {' '}
            {dict['Next Section']} {'>'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
