import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Row, Col } from 'react-bootstrap'
import Header from '../Components/Header'
import axios from 'axios'
import { backendLink } from '../keys_dev'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import ControlPanelSectionPopup from '../Components/ControlPanelSectionPopup'
import { ReactComponent as Edit } from '../images/edit.svg'
import { ReactComponent as Trash } from '../images/trash.svg'
import { useSnackbar } from 'notistack'

export default function ControlPanel() {
  const [selected, setSelected] = useState('Courses')
  const [serviceName, setServiceName] = useState()
  const [response, setResponse] = useState()
  const { enqueueSnackbar } = useSnackbar()

  const changeSelected = (newSelection) => {
    setSelected(newSelection)
    setServiceName()
    setResponse()
  }
  function DropdownMenu() {
    function DropdownItem(props) {
      const handleClick = (event) => {
        setServiceName(event.target.outerText)
        if (response === '') setResponse()
        else setResponse('')
      }
      return (
        <div className='courseFilterDropdownItem' onClick={handleClick}>
          {props.children}
        </div>
      )
    }

    switch (selected) {
      case 'Companies':
        return (
          <div className='controlPanelDropdown'>
            <DropdownItem>Create Company</DropdownItem>
            <DropdownItem>Delete Company</DropdownItem>
            <DropdownItem>Update Company</DropdownItem>
          </div>
        )
      case 'Courses':
        return (
          <div className='controlPanelDropdown'>
            <DropdownItem>Create Course</DropdownItem>
            <DropdownItem>Delete Course</DropdownItem>
            <DropdownItem>Update Course</DropdownItem>
          </div>
        )

      case 'Instructors':
        return (
          <div className='controlPanelDropdown'>
            <DropdownItem>Create Instructor</DropdownItem>
            <DropdownItem>Delete Instructor</DropdownItem>
            <DropdownItem>Update Instructor</DropdownItem>
          </div>
        )
      case 'Users':
        return (
          <div className='controlPanelDropdown'>
            <DropdownItem>Create User</DropdownItem>
            <DropdownItem>Delete User</DropdownItem>
            <DropdownItem>Update User</DropdownItem>
          </div>
        )

      default:
        return null
    }
  }

  const DisplayOptions = () => {
    const [open, setOpen] = useState(false)

    const handleButtonClick = () => {
      setOpen(!open)
    }
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          marginTop: '1vw',
        }}
      >
        <div>
          <Button className='controlPanelButton' onClick={handleButtonClick}>
            <p style={{ marginTop: '0.1vw' }}> {selected} Commands</p>
            {open ? (
              <p style={{ marginTop: '0vw', marginLeft: '0.3vw' }}>▲</p>
            ) : (
              <p style={{ marginTop: '0.2vw', marginLeft: '0.3vw' }}>▼</p>
            )}
          </Button>
          {open && <DropdownMenu />}
        </div>
      </div>
    )
  }

  const axiosCallHandler = (data, e) => {
    if (e) e.currentTarget.disabled = true
    switch (serviceName) {
      case 'Create Company':
        axios({
          url: `${backendLink}/api/v1/company`,
          method: 'POST',
          data: data,
        })
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar('Company created!', { variant: 'success' })
              setServiceName()
              if (response === 'Company created!') {
                setResponse('Company created! ')
              } else {
                setResponse('Company created!')
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
        break
      case 'Delete Company':
        axios({
          url: `${backendLink}/api/v1/company/${data}`,
          method: 'DELETE',
        })
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar('Company deleted!', { variant: 'success' })
              setServiceName()
              if (response === 'Company deleted!') {
                setResponse('Company deleted! ')
              } else {
                setResponse('Company deleted!')
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
        break
      case 'Update Company':
        axios({
          url: `${backendLink}/api/v1/company/${data._id}`,
          method: 'PUT',
          data: data,
        })
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar('Company updated!', { variant: 'success' })
              setServiceName()
              if (response === 'Company updated!') {
                setResponse('Company updated! ')
              } else {
                setResponse('Company updated!')
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
        break
      case 'Create Course':
        console.log(data)
        axios({
          url: `${backendLink}/api/v1/course`,
          method: 'POST',
          data: data,
        })
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar('Course created!', { variant: 'success' })
              setServiceName()
              if (response === 'Course created!') {
                setResponse('Course created! ')
              } else {
                setResponse('Course created!')
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
        break
      case 'Delete Course':
        axios({
          url: `${backendLink}/api/v1/course/${data}`,
          method: 'DELETE',
        })
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar('Course deleted!', { variant: 'success' })
              setServiceName()
              if (response === 'Course deleted!') {
                setResponse('Course deleted! ')
              } else {
                setResponse('Course deleted!')
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
        break
      case 'Update Course':
        axios({
          url: `${backendLink}/api/v1/course/${data._id}`,
          method: 'PUT',
          data: data,
        })
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar('Course updated!', { variant: 'success' })
              setServiceName()
              if (response === 'Course updated!') {
                setResponse('Course updated! ')
              } else {
                setResponse('Course updated!')
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
        break
      case 'Create Instructor':
        axios({
          url: `${backendLink}/api/v1/instructor`,
          method: 'POST',
          data: data,
        })
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar('Instructor created!', { variant: 'success' })
              setServiceName()
              if (response === 'Instructor created!') {
                setResponse('Instructor created! ')
              } else {
                setResponse('Instructor created!')
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
        break
      case 'Delete Instructor':
        axios({
          url: `${backendLink}/api/v1/instructor/${data}`,
          method: 'DELETE',
        })
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar('Instructor deleted!', { variant: 'success' })
              setServiceName()
              if (response === 'Instructor deleted!') {
                setResponse('Instructor deleted! ')
              } else {
                setResponse('Instructor deleted!')
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
        break
      case 'Update Instructor':
        axios({
          url: `${backendLink}/api/v1/instructor/${data.id}`,
          method: 'PUT',
          data: data,
        })
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar('Instructor updated!', { variant: 'success' })
              setServiceName()
              if (response === 'Instructor updated!') {
                setResponse('Instructor updated! ')
              } else {
                setResponse('Instructor updated!')
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
        break
      case 'Create User':
        axios({
          url: `${backendLink}/api/v1/user`,
          method: 'POST',
          data: data,
        })
          .then((res) => {
            if (res.status === 201) {
              enqueueSnackbar('User created!', { variant: 'success' })
              setServiceName()
              if (response === 'User created!') {
                setResponse('User created! ')
              } else {
                setResponse('User created!')
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
        break
      case 'Delete User':
        axios({
          url: `${backendLink}/api/v1/user/${data}`,
          method: 'DELETE',
        })
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar('User deleted!', { variant: 'success' })
              setServiceName()
              if (response === 'User deleted!') {
                setResponse('User deleted! ')
              } else {
                setResponse('User deleted!')
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
        break
      case 'Update User':
        axios({
          url: `${backendLink}/api/v1/user/${data._id}`,
          method: 'PUT',
          data: data,
        })
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar('User updated!', { variant: 'success' })
              setServiceName()
              if (response === 'User updated!') {
                setResponse('User updated! ')
              } else {
                setResponse('User updated!')
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
        break

      default:
        break
    }
  }
  const Service = () => {
    const [data, setData] = useState()
    const handleDataChange = (event) => {
      setData((data) => ({ ...data, [event.target.id]: event.target.value }))
    }
    const handleSubmit = (e) => {
      axiosCallHandler(data, e)
      setData()
    }

    const DeleteCompany = () => {
      const [title, setTitle] = useState('Choose company to delete')
      const [chosenId, setChosenId] = useState()

      const [result, setResult] = useState(null)

      const handleCompanyDelete = (event) => {
        setTitle(event.target.innerText)
        setChosenId(event.target.id)
      }
      useEffect(() => {
        axios({
          url: `${backendLink}/api/v1/company`,
          method: 'GET',
        })
          .then((res) => {
            setResult(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }, [])

      const handleSubmitWithId = (e) => {
        axiosCallHandler(chosenId, e)
        setData()
      }
      return result ? (
        <div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <DropdownButton
              align={{ lg: 'start' }}
              id='dropdown-basic-button'
              title={title}
            >
              {result.companies.map((company) => (
                <Dropdown.Item
                  key={company._id}
                  id={company._id}
                  onClick={handleCompanyDelete}
                >
                  {company.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <Button
              style={{ width: '8vw' }}
              className='controlPanelButton'
              onClick={handleSubmitWithId}
            >
              <p style={{ marginTop: '0.1vw' }}> Delete Company</p>
            </Button>
          </div>
        </div>
      ) : null
    }

    const UpdateCompany = () => {
      const [title, setTitle] = useState('Choose company to update')
      const [chosenCompany, setChosenCompany] = useState()

      const [result, setResult] = useState(null)

      const handleCompanySelect = (event) => {
        setTitle(event.target.innerText)
        axios({
          url: `${backendLink}/api/v1/company/${event.target.id}`,
          method: 'GET',
        })
          .then((res) => {
            setChosenCompany(res.data.company)
          })
          .catch((err) => {
            console.log(err)
          })
      }
      useEffect(() => {
        axios({
          url: `${backendLink}/api/v1/company`,
          method: 'GET',
        })
          .then((res) => {
            setResult(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }, [])

      const handleCompanyChange = (event) => {
        setChosenCompany((chosenCompany) => ({
          ...chosenCompany,
          [event.target.id]: event.target.value,
        }))
      }

      const handleSubmitWithId = (e) => {
        axiosCallHandler(chosenCompany, e)
        setData()
      }
      return result ? (
        <div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <DropdownButton
              align={{ lg: 'start' }}
              id='dropdown-basic-button'
              title={title}
            >
              {result.companies.map((company) => (
                <Dropdown.Item
                  key={company._id}
                  id={company._id}
                  onClick={handleCompanySelect}
                >
                  {company.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>

          {chosenCompany && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginTop: '1vw',
              }}
            >
              <Form style={{ width: '65vw' }}>
                <Row>
                  <Form.Group
                    style={{ display: 'flex', justifyContent: 'center' }}
                    as={Col}
                    controlId='name'
                  >
                    <Form.Control
                      onChange={handleCompanyChange}
                      style={{ width: '30vw' }}
                      value={chosenCompany.name}
                      className='LoginSignupFormControl'
                    />
                  </Form.Group>
                </Row>
              </Form>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <Button
              style={{ width: '8vw' }}
              className='controlPanelButton'
              onClick={handleSubmitWithId}
            >
              <p style={{ marginTop: '0.1vw' }}> Update Company</p>
            </Button>
          </div>
        </div>
      ) : null
    }

    const CreateCourse = () => {
      const [show, setShow] = useState(false)
      const [editSectionShowIndex, setEditSectionShowIndex] = useState(null)
      const [sections, setSections] = useState([])
      const [instructor, setInstructor] = useState({
        name: 'Choose course instructor',
        id: '',
      })
      const [field, setField] = useState('Choose course field')
      const [subField, setSubField] = useState('Choose course sub field')
      const [country, setCountry] = useState('Choose course country')
      const [courseData, setCourseData] = useState()

      const [result, setResult] = useState(null)
      useEffect(() => {
        axios({
          url: `${backendLink}/api/v1/instructor`,
          method: 'GET',
        })
          .then((res) => {
            setResult(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }, [])

      const handleDataChange = (event) => {
        setCourseData((courseData) => ({
          ...courseData,
          [event.target.id]: event.target.value,
        }))
      }

      const handleSubmitCourse = (e) => {
        var axiosData = courseData
        let errors = false
        if (!axiosData || !axiosData.name) {
          enqueueSnackbar('Course Name is required', { variant: 'error' })
          errors = true
        }
        if (!axiosData || !axiosData.description) {
          enqueueSnackbar('Course Description is required', {
            variant: 'error',
          })
          errors = true
        }
        if (!instructor.id) {
          enqueueSnackbar('No Instructor Chosen', { variant: 'error' })
          errors = true
        }
        if (field === 'Choose course field') {
          enqueueSnackbar('No Field Chosen', { variant: 'error' })
          errors = true
        }
        if (country === 'Choose course country') {
          enqueueSnackbar('No Country Chosen', { variant: 'error' })
          errors = true
        }
        if (
          subField === 'Choose course sub field' &&
          field === 'Joints' &&
          country == 'Italy'
        ) {
          enqueueSnackbar('No Sub Field Chosen', { variant: 'error' })
          errors = true
        }
        let i = 1
        sections.forEach((section) => {
          if (!section.name) {
            enqueueSnackbar(`Section ${i} Name is missing`, {
              variant: 'error',
            })
            errors = true
          }
          if (!section.time) {
            enqueueSnackbar(`Section ${i} Time is missing`, {
              variant: 'error',
            })
            errors = true
          }
          if (!section.description) {
            enqueueSnackbar(`Section ${i} Description is missing`, {
              variant: 'error',
            })
            errors = true
          }
          if (!section.contentPath) {
            enqueueSnackbar(`Section ${i} has no Video/PDF Uploaded`, {
              variant: 'error',
            })
            errors = true
          }
          if (section.assessment.position === 'Choose assessment position') {
            enqueueSnackbar(`Section ${i} Assessment position not specified`, {
              variant: 'error',
            })
            errors = true
          }
          i++
        })
        if (!errors) {
          axiosData.instructorId = instructor.id
          axiosData.field = field
          axiosData.sections = sections
          axiosData.country = country

          if (field === 'Joints' && country == 'Italy')
            axiosData.subField = subField
          console.log(axiosData)
          axiosCallHandler(axiosData, e)
        }
      }

      const handleInstructor = (event) => {
        setInstructor({
          name: event.target.innerText,
          id: event.target.id,
        })
      }
      const handleField = (event) => {
        setField(event.target.innerText)
      }
      const handleCountry = (event) => {
        setCountry(event.target.innerText)
      }
      const handleSubField = (event) => {
        setSubField(event.target.innerText)
      }

      const handleAddSectionModal = () => {
        setShow(true)
      }
      const handleAddSection = (newSection) => {
        setSections((sections) => [...sections, newSection])
        setShow(false)
      }
      const handleCloseSection = () => {
        setShow(false)
      }
      const handleSaveEditSection = (section, index) => {
        var array = [...sections]
        array.splice(index, 1, section)
        setSections(array)
        setEditSectionShowIndex(null)
      }
      const handleCloseEditSection = () => {
        setEditSectionShowIndex(null)
      }
      const handleEditSection = (index) => {
        setEditSectionShowIndex(index)
      }
      const handleDeleteSection = (index) => {
        var array = [...sections]
        array.splice(index, 1)
        setSections(array)
      }

      function DisplaySections() {
        return sections.map((section, index) => (
          <tr key={index}>
            <td className='syllabusChapterNameCP' style={{ width: '20vw' }}>
              <p style={{ marginLeft: '0.5vw' }}>{section.name}</p>
            </td>
            <td>
              <div
                className='courseCardField'
                style={{ width: '5vw', display: 'flex' }}
              >
                <div>
                  <Edit
                    onClick={() => handleEditSection(index)}
                    className='syllabusTableCPOption'
                    style={{
                      width: '1.6vw',
                      color: '#3f6989',
                      marginRight: '1vw',
                    }}
                  />
                  <ControlPanelSectionPopup
                    editSectionShowIndex={editSectionShowIndex}
                    sectionIndex={index}
                    handleSaveEditSection={handleSaveEditSection}
                    handleCloseEditSection={handleCloseEditSection}
                    questions={section.assessment.questions}
                    sectionContentPath={section.contentPath}
                    sectionName={section.name}
                    sectionDescription={section.description}
                    sectionTime={section.time}
                    assessmentPos={section.assessment.position}
                  />
                </div>
                <div>
                  <Trash
                    onClick={() => handleDeleteSection(index)}
                    className='syllabusTableCPOption'
                    style={{ width: '1.3vw', color: '#AB2318' }}
                  />
                </div>
              </div>
            </td>
          </tr>
        ))
      }
      return result ? (
        <div>
          <ControlPanelSectionPopup
            editSectionShowIndex={'-'}
            sectionIndex={''}
            show={show}
            handleAddSection={handleAddSection}
            handleCloseSection={handleCloseSection}
            questions={[]}
            sectionContentPath={''}
            sectionName={''}
            sectionDescription={''}
            sectionTime={''}
            assessmentPos={'Choose assessment position'}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
              marginBottom: '1vw',
            }}
          >
            <Form style={{ width: '65vw' }}>
              <Row>
                <Form.Group
                  style={{ display: 'flex', justifyContent: 'center' }}
                  as={Col}
                  controlId='name'
                >
                  <Form.Control
                    onChange={handleDataChange}
                    style={{ width: '30vw' }}
                    placeholder='Course Name'
                    className='LoginSignupFormControl'
                  />
                </Form.Group>
                <Form.Group
                  style={{ display: 'flex', justifyContent: 'center' }}
                  as={Col}
                  controlId='description'
                >
                  <Form.Control
                    onChange={handleDataChange}
                    style={{ width: '30vw' }}
                    placeholder='Course Description'
                    className='LoginSignupFormControl'
                  />
                </Form.Group>
              </Row>

              <Row style={{ marginTop: '1vw' }}>
                <Form.Group
                  style={{ display: 'flex' }}
                  as={Col}
                  controlId='name'
                >
                  <div
                    style={{
                      width: '33%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <DropdownButton
                      align={{ lg: 'start' }}
                      id='instructor'
                      title={instructor.name}
                    >
                      {result.instructors.map((instructor) => (
                        <Dropdown.Item
                          key={instructor._id}
                          id={instructor._id}
                          onClick={handleInstructor}
                        >
                          {instructor.name}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                  <div
                    style={{
                      width: '33%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <DropdownButton
                      align={{ lg: 'start' }}
                      id='field'
                      title={field}
                    >
                      <Dropdown.Item key='Skin' id='Skin' onClick={handleField}>
                        Skin
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Joints'
                        id='Joints'
                        onClick={handleField}
                      >
                        Joints
                      </Dropdown.Item>
                      <Dropdown.Item key='Eyes' id='Eyes' onClick={handleField}>
                        Eyes
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Aesthetics'
                        id='Aesthetics'
                        onClick={handleField}
                      >
                        Aesthetics
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Regenerative'
                        id='Regenerative'
                        onClick={handleField}
                      >
                        Regenerative
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Neurosciences'
                        id='Neurosciences'
                        onClick={handleField}
                      >
                        Neurosciences
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Ginecology'
                        id='Ginecology'
                        onClick={handleField}
                      >
                        Ginecology
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Cardiometabolis'
                        id='Cardiometabolis'
                        onClick={handleField}
                      >
                        Cardiometabolis
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Respiratory & Anti-Infective'
                        id='Respiratory & Anti-Infective'
                        onClick={handleField}
                      >
                        Respiratory & Anti-Infective
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                  <div
                    style={{
                      width: '33%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <DropdownButton
                      align={{ lg: 'start' }}
                      id='country'
                      title={country}
                    >
                      <Dropdown.Item
                        key='Egypt'
                        id='Egypt'
                        onClick={handleCountry}
                      >
                        Egypt
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Morocco'
                        id='Morocco'
                        onClick={handleCountry}
                      >
                        Morocco
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Global'
                        id='Global'
                        onClick={handleCountry}
                      >
                        Global
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Italy'
                        id='Italy'
                        onClick={handleCountry}
                      >
                        Italy
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </Form.Group>
              </Row>
              {field === 'Joints' && (
                <Row style={{ marginTop: '1vw' }}>
                  <Form.Group
                    style={{ display: 'flex' }}
                    as={Col}
                    controlId='subField'
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <DropdownButton
                        align={{ lg: 'start' }}
                        id='subField'
                        title={subField}
                      >
                        <Dropdown.Item
                          key='Osteoporosis'
                          id='Osteoporosis'
                          onClick={handleSubField}
                        >
                          Osteoporosis
                        </Dropdown.Item>
                        <Dropdown.Item
                          key='Pain'
                          id='Pain'
                          onClick={handleSubField}
                        >
                          Pain
                        </Dropdown.Item>
                        <Dropdown.Item
                          key='Corticosteroids'
                          id='Corticosteroids'
                          onClick={handleSubField}
                        >
                          Corticosteroids
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </Form.Group>
                </Row>
              )}
            </Form>
          </div>

          <p
            className='courseAboutTitle'
            style={{
              fontSize: '1.8vw',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Sections:{' '}
          </p>

          {sections.length > 0 ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className='syllabusTableWrapper' style={{ width: '30vw' }}>
                <table className='syllabusTableCP' style={{ width: '29.9vw' }}>
                  <tbody>
                    <DisplaySections></DisplaySections>
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <Button
              style={{ width: '8vw' }}
              className='controlPanelButton'
              onClick={handleAddSectionModal}
            >
              <p style={{ marginTop: '0.1vw' }}> Add Section</p>
            </Button>
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <Button
              style={{ width: '8vw' }}
              className='controlPanelButton'
              onClick={handleSubmitCourse}
            >
              <p style={{ marginTop: '0.1vw' }}> Create Course</p>
            </Button>
          </div>
        </div>
      ) : null
    }
    const DeleteCourse = () => {
      const [title, setTitle] = useState('Choose course to delete')
      const [chosenId, setChosenId] = useState()

      const [result, setResult] = useState(null)

      const handleCompanyDelete = (event) => {
        setTitle(event.target.innerText)
        setChosenId(event.target.id)
      }
      useEffect(() => {
        axios({
          url: `${backendLink}/api/v1/course/getCourses`,
          method: 'POST',
        })
          .then((res) => {
            setResult(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }, [])

      const handleSubmitWithId = (e) => {
        axiosCallHandler(chosenId, e)
        setData()
      }
      return result ? (
        <div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <DropdownButton
              align={{ lg: 'start' }}
              id='dropdown-basic-button'
              title={title}
            >
              {result.courses.map((course) => (
                <Dropdown.Item
                  key={course._id}
                  id={course._id}
                  onClick={handleCompanyDelete}
                >
                  {course.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          {chosenId ? (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginTop: '1vw',
              }}
            >
              <Button
                style={{ width: '8vw' }}
                className='controlPanelButton'
                onClick={handleSubmitWithId}
              >
                <p style={{ marginTop: '0.1vw' }}> Delete Course</p>
              </Button>
            </div>
          ) : null}
        </div>
      ) : null
    }
    const UpdateCourse = () => {
      const [show, setShow] = useState(false)
      const [editSectionShowIndex, setEditSectionShowIndex] = useState(null)
      const [sections, setSections] = useState([])

      const [title, setTitle] = useState('Choose course to update')

      const [instructor, setInstructor] = useState({
        name: 'Choose course instructor',
        id: '',
      })
      const [field, setField] = useState()
      const [country, setCountry] = useState()
      const [result, setResult] = useState(null)
      const [resultCourses, setResultCourses] = useState(null)
      const [chosenCourse, setChosenCourse] = useState()

      const handleCourseSelect = (event) => {
        setTitle(event.target.innerText)
        axios({
          url: `${backendLink}/api/v1/course/${event.target.id}`,
          method: 'GET',
        })
          .then((res) => {
            setChosenCourse(res.data.course)
            setField(res.data.course.field)
            setCountry(res.data.course.country)
            for (let index = 0; index < result.instructors.length; index++) {
              const instructort = result.instructors[index]
              if (instructort._id === res.data.course.instructorId) {
                setInstructor({
                  name: instructort.name,
                  id: instructort._id,
                })
              }
            }
            setSections(res.data.course.sections)
          })
          .catch((err) => {
            console.log(err)
          })
      }
      useEffect(() => {
        axios({
          url: `${backendLink}/api/v1/course/getCourses`,
          method: 'POST',
        })
          .then((res) => {
            setResultCourses(res.data)
          })
          .catch((err) => {
            console.log(err)
          })

        axios({
          url: `${backendLink}/api/v1/instructor`,
          method: 'GET',
        })
          .then((res) => {
            setResult(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }, [])

      const handleCourseChange = (event) => {
        setChosenCourse((chosenCourse) => ({
          ...chosenCourse,
          [event.target.id]: event.target.value,
        }))
      }

      const handleSubmitCourse = (e) => {
        var axiosData = chosenCourse
        let errors = false
        if (!axiosData || !axiosData.name) {
          enqueueSnackbar('Course Name is required', { variant: 'error' })
          errors = true
        }
        if (!axiosData || !axiosData.description) {
          enqueueSnackbar('Course Description is required', {
            variant: 'error',
          })
          errors = true
        }
        if (!instructor.id) {
          enqueueSnackbar('No Instructor Chosen', { variant: 'error' })
          errors = true
        }
        if (field === 'Choose course field') {
          enqueueSnackbar('No Field Chosen', { variant: 'error' })
          errors = true
        }
        if (country === 'Choose course country') {
          enqueueSnackbar('No Country Chosen', { variant: 'error' })
          errors = true
        }
        let i = 1
        sections.forEach((section) => {
          if (!section.name) {
            enqueueSnackbar(`Section ${i} Name is missing`, {
              variant: 'error',
            })
            errors = true
          }
          if (!section.time) {
            enqueueSnackbar(`Section ${i} Time is missing`, {
              variant: 'error',
            })
            errors = true
          }
          if (!section.description) {
            enqueueSnackbar(`Section ${i} Description is missing`, {
              variant: 'error',
            })
            errors = true
          }
          if (!section.contentPath) {
            enqueueSnackbar(`Section ${i} has no Video/PDF Uploaded`, {
              variant: 'error',
            })
            errors = true
          }
          if (section.assessment.position === 'Choose assessment position') {
            enqueueSnackbar(`Section ${i} Assessment position not specified`, {
              variant: 'error',
            })
            errors = true
          }
          i++
        })
        if (!errors) {
          axiosData.instructorId = instructor.id
          axiosData.field = field
          axiosData.sections = sections
          axiosData.country = country
          console.log(axiosData)
          axiosCallHandler(axiosData, e)
        }
      }

      const handleInstructor = (event) => {
        setInstructor({
          name: event.target.innerText,
          id: event.target.id,
        })
      }
      const handleField = (event) => {
        setField(event.target.innerText)
      }
      const handleCountry = (event) => {
        setCountry(event.target.innerText)
      }

      function DisplaySections() {
        return sections.map((section, index) => (
          <tr key={index}>
            <td className='syllabusChapterNameCP' style={{ width: '20vw' }}>
              <p style={{ marginLeft: '0.5vw' }}>{section.name}</p>
            </td>
            <td>
              <div
                className='courseCardField'
                style={{ width: '5vw', display: 'flex' }}
              >
                <div>
                  <Edit
                    onClick={() => handleEditSection(index)}
                    className='syllabusTableCPOption'
                    style={{
                      width: '1.6vw',
                      color: '#3f6989',
                      marginRight: '1vw',
                    }}
                  />
                  <ControlPanelSectionPopup
                    editSectionShowIndex={editSectionShowIndex}
                    sectionIndex={index}
                    handleSaveEditSection={handleSaveEditSection}
                    handleCloseEditSection={handleCloseEditSection}
                    questions={section.assessment.questions}
                    sectionContentPath={section.contentPath}
                    sectionName={section.name}
                    sectionDescription={section.description}
                    sectionTime={section.time}
                    assessmentPos={section.assessment.position}
                  />
                </div>
                <div>
                  <Trash
                    onClick={() => handleDeleteSection(index)}
                    className='syllabusTableCPOption'
                    style={{ width: '1.3vw', color: '#AB2318' }}
                  />
                </div>
              </div>
            </td>
          </tr>
        ))
      }

      const handleAddSectionModal = () => {
        setShow(true)
      }
      const handleAddSection = (newSection) => {
        setSections((sections) => [...sections, newSection])
        setShow(false)
      }
      const handleSaveEditSection = (section, index) => {
        var array = [...sections]
        array.splice(index, 1, section)
        setSections(array)
        setEditSectionShowIndex(null)
      }
      const handleCloseEditSection = () => {
        setEditSectionShowIndex(null)
      }
      const handleEditSection = (index) => {
        setEditSectionShowIndex(index)
      }
      const handleDeleteSection = (index) => {
        var array = [...sections]
        array.splice(index, 1)
        setSections(array)
      }
      return resultCourses && result ? (
        <div>
          <ControlPanelSectionPopup
            editSectionShowIndex={'-'}
            sectionIndex={''}
            show={show}
            handleAddSection={handleAddSection}
            questions={[]}
            sectionContentPath={''}
            sectionName={''}
            sectionDescription={''}
            sectionTime={''}
            assessmentPos={'Choose assessment position'}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <DropdownButton
              align={{ lg: 'start' }}
              id='dropdown-basic-button'
              title={title}
            >
              {resultCourses.courses.map((course) => (
                <Dropdown.Item
                  key={course._id}
                  id={course._id}
                  onClick={handleCourseSelect}
                >
                  {course.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          {chosenCourse ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '1vw',
                }}
              >
                <Form style={{ width: '65vw' }}>
                  <Row>
                    <Form.Group
                      style={{ display: 'flex', justifyContent: 'center' }}
                      as={Col}
                      controlId='name'
                    >
                      <Form.Control
                        onChange={handleCourseChange}
                        style={{ width: '30vw' }}
                        value={chosenCourse.name}
                        placeholder='Course Name'
                        className='LoginSignupFormControl'
                      />
                    </Form.Group>
                    <Form.Group
                      style={{ display: 'flex', justifyContent: 'center' }}
                      as={Col}
                      controlId='description'
                    >
                      <Form.Control
                        onChange={handleCourseChange}
                        style={{ width: '30vw' }}
                        value={chosenCourse.description}
                        placeholder='Course Description'
                        className='LoginSignupFormControl'
                      />
                    </Form.Group>
                  </Row>

                  <Row style={{ marginTop: '1vw' }}>
                    <Form.Group
                      style={{ display: 'flex' }}
                      as={Col}
                      controlId='name'
                    >
                      <div
                        style={{
                          width: '33%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <DropdownButton
                          align={{ lg: 'start' }}
                          id='instructor'
                          title={instructor.name}
                        >
                          {result.instructors.map((instructor) => (
                            <Dropdown.Item
                              key={instructor._id}
                              id={instructor._id}
                              onClick={handleInstructor}
                            >
                              {instructor.name}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </div>
                      <div
                        style={{
                          width: '33%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <DropdownButton
                          align={{ lg: 'start' }}
                          id='field'
                          title={country}
                        >
                          <Dropdown.Item
                            key='Egypt'
                            id='Egypt'
                            onClick={handleCountry}
                          >
                            Egypt
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Morocco'
                            id='Morocco'
                            onClick={handleCountry}
                          >
                            Morocco
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Global'
                            id='Global'
                            onClick={handleCountry}
                          >
                            Global
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Italy'
                            id='Italy'
                            onClick={handleCountry}
                          >
                            Italy
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                      <div
                        style={{
                          width: '33%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <DropdownButton
                          align={{ lg: 'start' }}
                          id='field'
                          title={field}
                        >
                          <Dropdown.Item
                            key='Skin'
                            id='Skin'
                            onClick={handleField}
                          >
                            Skin
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Joints'
                            id='Joints'
                            onClick={handleField}
                          >
                            Joints
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Eyes'
                            id='Eyes'
                            onClick={handleField}
                          >
                            Eyes
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Aesthetics'
                            id='Aesthetics'
                            onClick={handleField}
                          >
                            Aesthetics
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Regenerative'
                            id='Regenerative'
                            onClick={handleField}
                          >
                            Regenerative
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Neurosciences'
                            id='Neurosciences'
                            onClick={handleField}
                          >
                            Neurosciences
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Ginecology'
                            id='Ginecology'
                            onClick={handleField}
                          >
                            Ginecology
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Cardiometabolis'
                            id='Cardiometabolis'
                            onClick={handleField}
                          >
                            Cardiometabolis
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Respiratory & Anti-Infective'
                            id='Respiratory & Anti-Infective'
                            onClick={handleField}
                          >
                            Respiratory & Anti-Infective
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </Form.Group>
                  </Row>
                </Form>
              </div>
              <p
                className='courseAboutTitle'
                style={{
                  fontSize: '1.8vw',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Sections:{' '}
              </p>

              {sections.length > 0 ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                    className='syllabusTableWrapper'
                    style={{ width: '30vw' }}
                  >
                    <table
                      className='syllabusTableCP'
                      style={{ width: '29.9vw' }}
                    >
                      <tbody>
                        <DisplaySections></DisplaySections>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '1vw',
                }}
              >
                <Button
                  style={{ width: '8vw' }}
                  className='controlPanelButton'
                  onClick={handleAddSectionModal}
                >
                  <p style={{ marginTop: '0.1vw' }}> Add Section</p>
                </Button>
              </div>

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '1vw',
                }}
              >
                <Button
                  style={{ width: '8vw' }}
                  className='controlPanelButton'
                  onClick={handleSubmitCourse}
                >
                  <p style={{ marginTop: '0.1vw' }}> Update Course</p>
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      ) : null
    }
    const CreateInstructor = () => {
      const [data, setData] = useState()
      const [companies, setCompanies] = useState()
      const [company, setCompany] = useState({
        name: "Choose instructor's company",
        id: '',
      })
      const [field, setField] = useState("Choose instructor's field")

      useEffect(() => {
        axios({
          url: `${backendLink}/api/v1/company`,
          method: 'GET',
        })
          .then((res) => {
            console.log(res.data)
            setCompanies(res.data.companies)
          })
          .catch((err) => {
            console.log(err)
          })
      }, [])
      const handleDataChange = (event) => {
        setData((data) => ({ ...data, [event.target.id]: event.target.value }))
      }
      const handleSubmit = (e) => {
        var axiosData = data
        axiosData.companyId = company.id
        axiosData.field = field
        axiosCallHandler(axiosData, e)
        setData()
      }

      const handleCompany = (event) => {
        setCompany({
          name: event.target.innerText,
          id: event.target.id,
        })
      }
      const handleField = (event) => {
        setField(event.target.innerText)
      }
      return (
        <div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <Form style={{ width: '65vw' }}>
              <Row>
                <Form.Group
                  style={{ display: 'flex', justifyContent: 'center' }}
                  as={Col}
                  controlId='name'
                >
                  <Form.Control
                    onChange={handleDataChange}
                    style={{ width: '30vw' }}
                    placeholder='Instructor name'
                    className='LoginSignupFormControl'
                  />
                </Form.Group>
              </Row>
              <Row style={{ marginTop: '1vw' }}>
                <Form.Group
                  style={{ display: 'flex', justifyContent: 'center' }}
                  as={Col}
                  controlId='email'
                >
                  <Form.Control
                    onChange={handleDataChange}
                    style={{ width: '30vw' }}
                    placeholder='Instructor email'
                    className='LoginSignupFormControl'
                  />
                </Form.Group>
                <Form.Group
                  style={{ display: 'flex', justifyContent: 'center' }}
                  as={Col}
                  controlId='password'
                >
                  <Form.Control
                    type='password'
                    onChange={handleDataChange}
                    style={{ width: '30vw' }}
                    placeholder='Instructor password'
                    className='LoginSignupFormControl'
                  />
                </Form.Group>
              </Row>
              <Row style={{ marginTop: '1vw' }}>
                <Form.Group
                  style={{ display: 'flex' }}
                  as={Col}
                  controlId='name'
                >
                  <div
                    style={{
                      width: '30.5vw',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {companies ? (
                      <DropdownButton
                        align={{ lg: 'start' }}
                        id='instructor'
                        title={company.name}
                      >
                        {companies.map((company) => (
                          <Dropdown.Item
                            key={company._id}
                            id={company._id}
                            onClick={handleCompany}
                          >
                            {company.name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    ) : null}
                  </div>
                  <div
                    style={{
                      width: '33vw',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <DropdownButton
                      align={{ lg: 'start' }}
                      id='field'
                      title={field}
                    >
                      <Dropdown.Item key='Skin' id='Skin' onClick={handleField}>
                        Skin
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Joints'
                        id='Joints'
                        onClick={handleField}
                      >
                        Joints
                      </Dropdown.Item>
                      <Dropdown.Item key='Eyes' id='Eyes' onClick={handleField}>
                        Eyes
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Aesthetics'
                        id='Aesthetics'
                        onClick={handleField}
                      >
                        Aesthetics
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Regenerative'
                        id='Regenerative'
                        onClick={handleField}
                      >
                        Regenerative
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Neurosciences'
                        id='Neurosciences'
                        onClick={handleField}
                      >
                        Neurosciences
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Ginecology'
                        id='Ginecology'
                        onClick={handleField}
                      >
                        Ginecology
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Cardiometabolis'
                        id='Cardiometabolis'
                        onClick={handleField}
                      >
                        Cardiometabolis
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Respiratory & Anti-Infective'
                        id='Respiratory & Anti-Infective'
                        onClick={handleField}
                      >
                        Respiratory & Anti-Infective
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </Form.Group>
              </Row>
            </Form>
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <Button
              style={{ width: '8vw' }}
              className='controlPanelButton'
              onClick={handleSubmit}
            >
              <p style={{ marginTop: '0.1vw' }}> Create Instructor</p>
            </Button>
          </div>
        </div>
      )
    }
    const DeleteInstructor = () => {
      const [title, setTitle] = useState('Choose instructor to delete')
      const [chosenId, setChosenId] = useState()

      const [result, setResult] = useState(null)

      const handleCompanyDelete = (event) => {
        setTitle(event.target.innerText)
        setChosenId(event.target.id)
      }
      useEffect(() => {
        axios({
          url: `${backendLink}/api/v1/instructor`,
          method: 'GET',
        })
          .then((res) => {
            setResult(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }, [])

      const handleSubmitWithId = (e) => {
        axiosCallHandler(chosenId, e)
        setData()
      }
      return result ? (
        <div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <DropdownButton
              align={{ lg: 'start' }}
              id='dropdown-basic-button'
              title={title}
            >
              {result.instructors.map((instructor) => (
                <Dropdown.Item
                  key={instructor._id}
                  id={instructor._id}
                  onClick={handleCompanyDelete}
                >
                  {instructor.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          {chosenId && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginTop: '1vw',
              }}
            >
              <Button
                style={{ width: '8vw' }}
                className='controlPanelButton'
                onClick={handleSubmitWithId}
              >
                <p style={{ marginTop: '0.1vw' }}> Delete Instructor</p>
              </Button>
            </div>
          )}
        </div>
      ) : null
    }
    const UpdateInstructor = () => {
      const [instructor, setInstructor] = useState({
        name: 'Choose instructor to update',
        id: null,
      })
      const [instructors, setInstructors] = useState()
      const [data, setData] = useState()
      const [companies, setCompanies] = useState()
      const [company, setCompany] = useState({
        name: "Choose instructor's company",
        id: '',
      })
      const [field, setField] = useState("Choose instructor's field")

      useEffect(() => {
        axios({
          url: `${backendLink}/api/v1/instructor`,
          method: 'GET',
        })
          .then((res) => {
            setInstructors(res.data.instructors)
          })
          .catch((err) => {
            console.log(err)
          })

        axios({
          url: `${backendLink}/api/v1/company`,
          method: 'GET',
        })
          .then((res) => {
            setCompanies(res.data.companies)
          })
          .catch((err) => {
            console.log(err)
          })
      }, [])
      const handleDataChange = (event) => {
        setData((data) => ({ ...data, [event.target.id]: event.target.value }))
      }
      const handleSubmit = (e) => {
        var axiosData = data
        axiosData.companyId = company.id
        axiosData.field = field
        axiosData.id = instructor.id
        axiosCallHandler(data, e)
        setData()
      }

      const handleCompany = (event) => {
        setCompany({
          name: event.target.innerText,
          id: event.target.id,
        })
      }
      const handleField = (event) => {
        setField(event.target.innerText)
      }
      const handleInstructorSelect = (event) => {
        setInstructor({
          name: event.target.innerText,
          id: event.target.id,
        })

        for (let index = 0; index < instructors.length; index++) {
          const instructortemp = instructors[index]
          if (instructortemp._id === event.target.id) {
            setData({
              name: instructortemp.name,
              email: instructortemp.email,
            })
            setField(instructortemp.field)

            for (let j = 0; j < companies.length; j++) {
              const company = companies[j]
              if (company._id === instructortemp.companyId) {
                setCompany({
                  name: company.name,
                  id: company._id,
                })
              }
            }
          }
        }
      }
      return instructors && instructor ? (
        <div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <DropdownButton
                align={{ lg: 'start' }}
                id='dropdown-basic-button'
                title={instructor.name}
              >
                {instructors.map((instructor) => (
                  <Dropdown.Item
                    key={instructor._id}
                    id={instructor._id}
                    onClick={handleInstructorSelect}
                  >
                    {instructor.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            {instructor.id && data ? (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '1vw',
                }}
              >
                <Form style={{ width: '65vw' }}>
                  <Row>
                    <Form.Group
                      style={{ display: 'flex', justifyContent: 'center' }}
                      as={Col}
                      controlId='name'
                    >
                      <Form.Control
                        onChange={handleDataChange}
                        style={{ width: '30vw' }}
                        value={data.name}
                        placeholder='Instructor name'
                        className='LoginSignupFormControl'
                      />
                    </Form.Group>
                    <Form.Group
                      style={{ display: 'flex', justifyContent: 'center' }}
                      as={Col}
                      controlId='email'
                    >
                      <Form.Control
                        onChange={handleDataChange}
                        style={{ width: '30vw' }}
                        value={data.email}
                        placeholder='Instructor email'
                        className='LoginSignupFormControl'
                      />
                    </Form.Group>
                  </Row>

                  <Row style={{ marginTop: '1vw' }}>
                    <Form.Group
                      style={{ display: 'flex' }}
                      as={Col}
                      controlId='name'
                    >
                      <div
                        style={{
                          width: '30.5vw',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {companies ? (
                          <DropdownButton
                            align={{ lg: 'start' }}
                            id='instructor'
                            title={company.name}
                          >
                            {companies.map((company) => (
                              <Dropdown.Item
                                key={company._id}
                                id={company._id}
                                onClick={handleCompany}
                              >
                                {company.name}
                              </Dropdown.Item>
                            ))}
                          </DropdownButton>
                        ) : null}
                      </div>
                      <div
                        style={{
                          width: '33vw',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <DropdownButton
                          align={{ lg: 'start' }}
                          id='field'
                          title={field}
                        >
                          <Dropdown.Item
                            key='Skin'
                            id='Skin'
                            onClick={handleField}
                          >
                            Skin
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Joints'
                            id='Joints'
                            onClick={handleField}
                          >
                            Joints
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Eyes'
                            id='Eyes'
                            onClick={handleField}
                          >
                            Eyes
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Aesthetics'
                            id='Aesthetics'
                            onClick={handleField}
                          >
                            Aesthetics
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Regenerative'
                            id='Regenerative'
                            onClick={handleField}
                          >
                            Regenerative
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Neurosciences'
                            id='Neurosciences'
                            onClick={handleField}
                          >
                            Neurosciences
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Ginecology'
                            id='Ginecology'
                            onClick={handleField}
                          >
                            Ginecology
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Cardiometabolis'
                            id='Cardiometabolis'
                            onClick={handleField}
                          >
                            Cardiometabolis
                          </Dropdown.Item>
                          <Dropdown.Item
                            key='Respiratory & Anti-Infective'
                            id='Respiratory & Anti-Infective'
                            onClick={handleField}
                          >
                            Respiratory & Anti-Infective
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            ) : null}
          </div>
          {instructor.id && data && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginTop: '1vw',
              }}
            >
              <Button
                style={{ width: '8vw' }}
                className='controlPanelButton'
                onClick={handleSubmit}
              >
                <p style={{ marginTop: '0.1vw' }}> Update Instructor</p>
              </Button>
            </div>
          )}
        </div>
      ) : null
    }
    const CreateUser = () => {
      const [data, setData] = useState({
        country: "Choose user's country",
        field: "Choose user's field",
      })
      const [lineManagers, setLineManagers] = useState([])
      const [lineManager, setLineManager] = useState({
        name: 'Choose line manager',
        id: null,
      })
      const lineManagerChange = (event) => {
        setLineManager({
          name: event.target.innerText,
          id: event.target.id,
        })
      }

      useEffect(() => {
        axios({
          url: `${backendLink}/api/v1/user/getAdmins`,
          method: 'GET',
        })
          .then((res) => {
            setLineManagers(res.data.users)
          })
          .catch((err) => {
            console.log(err)
          })
      }, [])

      const handleDataChange = (event) => {
        setData((data) => ({ ...data, [event.target.id]: event.target.value }))
      }
      const handleCountry = (event) => {
        setData((data) => ({ ...data, country: event.target.id }))
      }
      const handleField = (event) => {
        setData((data) => ({ ...data, field: event.target.id }))
      }
      const handleSubmit = (e) => {
        data.lineManager = lineManager
        axiosCallHandler(data, e)
      }

      return (
        <div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <Form style={{ width: '65vw' }}>
              <Row>
                <Form.Group
                  style={{ display: 'flex', justifyContent: 'center' }}
                  as={Col}
                  controlId='name'
                >
                  <Form.Control
                    onChange={handleDataChange}
                    style={{ width: '30vw' }}
                    placeholder='User name'
                    className='LoginSignupFormControl'
                  />
                </Form.Group>
              </Row>
              <Row style={{ marginTop: '1vw' }}>
                <Form.Group
                  style={{ display: 'flex', justifyContent: 'center' }}
                  as={Col}
                  controlId='email'
                >
                  <Form.Control
                    onChange={handleDataChange}
                    style={{ width: '30vw' }}
                    placeholder='User email'
                    className='LoginSignupFormControl'
                  />
                </Form.Group>
                <Form.Group
                  style={{ display: 'flex', justifyContent: 'center' }}
                  as={Col}
                  controlId='password'
                >
                  <Form.Control
                    type='password'
                    onChange={handleDataChange}
                    style={{ width: '30vw' }}
                    placeholder='User password'
                    className='LoginSignupFormControl'
                  />
                </Form.Group>
              </Row>
              <Row>
                <div
                  style={{
                    marginTop: '2vw',
                    width: '33%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <DropdownButton
                    align={{ lg: 'start' }}
                    id='country'
                    title={data.country}
                  >
                    <Dropdown.Item
                      key='Egypt'
                      id='Egypt'
                      value='Egypt'
                      onClick={handleCountry}
                    >
                      Egypt
                    </Dropdown.Item>
                    <Dropdown.Item
                      key='Morocco'
                      id='Morocco'
                      onClick={handleCountry}
                    >
                      Morocco
                    </Dropdown.Item>
                    <Dropdown.Item
                      key='Global'
                      id='Global'
                      onClick={handleCountry}
                    >
                      Global
                    </Dropdown.Item>
                    <Dropdown.Item
                      key='Italy'
                      id='Italy'
                      onClick={handleCountry}
                    >
                      Italy
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
                <div
                  style={{
                    marginTop: '2vw',
                    width: '33%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <DropdownButton
                    align={{ lg: 'start' }}
                    id='country'
                    title={lineManager.name}
                  >
                    {lineManagers.map((manager) => (
                      <Dropdown.Item
                        id={manager._id}
                        onClick={lineManagerChange}
                      >
                        {manager.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>
                <div
                  style={{
                    marginTop: '2vw',
                    width: '33%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <DropdownButton
                    align={{ lg: 'start' }}
                    id='field'
                    title={data.field}
                  >
                    <Dropdown.Item
                      key='All'
                      id='All'
                      value='All'
                      onClick={handleField}
                    >
                      All
                    </Dropdown.Item>
                    <Dropdown.Item key='Skin' id='Skin' onClick={handleField}>
                      Skin
                    </Dropdown.Item>
                    <Dropdown.Item
                      key='Joints'
                      id='Joints'
                      onClick={handleField}
                    >
                      Joints
                    </Dropdown.Item>
                    <Dropdown.Item key='Eyes' id='Eyes' onClick={handleField}>
                      Eyes
                    </Dropdown.Item>
                    <Dropdown.Item
                      key='Aesthetics'
                      id='Aesthetics'
                      onClick={handleField}
                    >
                      Aesthetics
                    </Dropdown.Item>
                    <Dropdown.Item
                      key='Regenerative'
                      id='Regenerative'
                      onClick={handleField}
                    >
                      Regenerative
                    </Dropdown.Item>
                    <Dropdown.Item
                      key='Neurosciences'
                      id='Neurosciences'
                      onClick={handleField}
                    >
                      Neurosciences
                    </Dropdown.Item>
                    <Dropdown.Item
                      key='Ginecology'
                      id='Ginecology'
                      onClick={handleField}
                    >
                      Ginecology
                    </Dropdown.Item>
                    <Dropdown.Item
                      key='Cardiometabolis'
                      id='Cardiometabolis'
                      onClick={handleField}
                    >
                      Cardiometabolis
                    </Dropdown.Item>
                    <Dropdown.Item
                      key='Respiratory & Anti-Infective'
                      id='Respiratory & Anti-Infective'
                      onClick={handleField}
                    >
                      Respiratory & Anti-Infective
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </Row>
            </Form>
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <Button
              style={{ width: '8vw' }}
              className='controlPanelButton'
              onClick={handleSubmit}
            >
              <p style={{ marginTop: '0.1vw' }}> Create User</p>
            </Button>
          </div>
        </div>
      )
    }
    const DeleteUser = () => {
      const [title, setTitle] = useState('Choose user to delete')
      const [chosenId, setChosenId] = useState()

      const [result, setResult] = useState(null)

      const handleCompanyDelete = (event) => {
        setTitle(event.target.innerText)
        setChosenId(event.target.id)
      }
      useEffect(() => {
        axios({
          url: `${backendLink}/api/v1/user/getUsers`,
          method: 'POST',
          data: {},
        })
          .then((res) => {
            setResult(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }, [])

      const handleSubmitWithId = (e) => {
        axiosCallHandler(chosenId, e)
        setData()
      }
      return result ? (
        <div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <DropdownButton
              align={{ lg: 'start' }}
              id='dropdown-basic-button'
              title={title}
            >
              {result.users.map((user) => (
                <Dropdown.Item
                  key={user._id}
                  id={user._id}
                  onClick={handleCompanyDelete}
                >
                  {user.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          {chosenId && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginTop: '1vw',
              }}
            >
              <Button
                style={{ width: '8vw' }}
                className='controlPanelButton'
                onClick={handleSubmitWithId}
              >
                <p style={{ marginTop: '0.1vw' }}> Delete User</p>
              </Button>
            </div>
          )}
        </div>
      ) : null
    }
    const UpdateUser = () => {
      const [data, setData] = useState({
        name: 'Choose user to update',
      })
      const [users, setUsers] = useState()
      const [lineManagers, setLineManagers] = useState([])
      const [lineManager, setLineManager] = useState({
        name: 'Choose line manager',
        id: null,
      })
      const lineManagerChange = (event) => {
        setLineManager({
          name: event.target.innerText,
          id: event.target.id,
        })
      }

      useEffect(() => {
        axios({
          url: `${backendLink}/api/v1/user/getUsers`,
          method: 'POST',
          data: {},
        })
          .then((res) => {
            setUsers(res.data.users)
          })
          .catch((err) => {
            console.log(err)
          })
        axios({
          url: `${backendLink}/api/v1/user/getAdmins`,
          method: 'GET',
        })
          .then((res) => {
            setLineManagers(res.data.users)
          })
          .catch((err) => {
            console.log(err)
          })
      }, [])

      const handleDataChange = (event) => {
        setData((data) => ({ ...data, [event.target.id]: event.target.value }))
      }
      const handleCountry = (event) => {
        setData((data) => ({ ...data, country: event.target.id }))
      }
      const handleField = (event) => {
        setData((data) => ({ ...data, field: event.target.id }))
      }

      const handleSubmit = (e) => {
        data.lineManager = lineManager
        axiosCallHandler(data, e)
        setData()
      }
      const handleUserChange = (event) => {
        for (let index = 0; index < users.length; index++) {
          const user = users[index]
          if (event.target.id === user._id) {
            setData({
              name: user.name,
              email: user.email,
              password: user.password,
              country: user.country,
              field: user.field,
              _id: user._id,
            })
            if (user.lineManager)
              setLineManager({
                name: user.lineManager.name,
                id: user.lineManager.id,
              })
            else
              setLineManager({
                name: 'Choose line manager',
                id: null,
              })
          }
        }
      }

      return users && data ? (
        <div>
          {console.log(data)}
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '1vw',
            }}
          >
            <DropdownButton
              align={{ lg: 'start' }}
              id='dropdown-basic-button'
              title={data.name}
            >
              {users.map((user) => (
                <Dropdown.Item
                  key={user._id}
                  id={user._id}
                  onClick={handleUserChange}
                >
                  {user.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          {data._id ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '1vw',
                }}
              >
                <Form style={{ width: '65vw' }}>
                  <Row>
                    <Form.Group
                      style={{ display: 'flex', justifyContent: 'center' }}
                      as={Col}
                      controlId='name'
                    >
                      <Form.Control
                        onChange={handleDataChange}
                        style={{ width: '30vw' }}
                        value={data.name}
                        placeholder='User name'
                        className='LoginSignupFormControl'
                      />
                    </Form.Group>
                  </Row>
                  <Row style={{ marginTop: '1vw' }}>
                    <Form.Group
                      style={{ display: 'flex', justifyContent: 'center' }}
                      as={Col}
                      controlId='email'
                    >
                      <Form.Control
                        onChange={handleDataChange}
                        style={{ width: '30vw' }}
                        value={data.email}
                        placeholder='User email'
                        className='LoginSignupFormControl'
                      />
                    </Form.Group>
                    <Form.Group
                      style={{ display: 'flex', justifyContent: 'center' }}
                      as={Col}
                      controlId='password'
                    >
                      <Form.Control
                        type='password'
                        onChange={handleDataChange}
                        style={{ width: '30vw' }}
                        value={data.password}
                        placeholder='User password'
                        className='LoginSignupFormControl'
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <div
                      style={{
                        marginTop: '2vw',
                        width: '33%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <DropdownButton
                        align={{ lg: 'start' }}
                        id='country'
                        title={data.country}
                      >
                        <Dropdown.Item
                          key='Egypt'
                          id='Egypt'
                          value='Egypt'
                          onClick={handleCountry}
                        >
                          Egypt
                        </Dropdown.Item>
                        <Dropdown.Item
                          key='Morocco'
                          id='Morocco'
                          onClick={handleCountry}
                        >
                          Morocco
                        </Dropdown.Item>
                        <Dropdown.Item
                          key='Global'
                          id='Global'
                          onClick={handleCountry}
                        >
                          Global
                        </Dropdown.Item>
                        <Dropdown.Item
                          key='Italy'
                          id='Italy'
                          onClick={handleCountry}
                        >
                          Italy
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div
                      style={{
                        marginTop: '2vw',
                        width: '33%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <DropdownButton
                        align={{ lg: 'start' }}
                        id='country'
                        title={lineManager.name}
                      >
                        {lineManagers.map((manager) => (
                          <Dropdown.Item
                            id={manager._id}
                            onClick={lineManagerChange}
                          >
                            {manager.name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </div>
                    <div
                      style={{
                        marginTop: '2vw',
                        width: '33%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <DropdownButton
                        align={{ lg: 'start' }}
                        id='field'
                        title={data.field}
                      >
                        <Dropdown.Item
                          key='All'
                          id='All'
                          value='All'
                          onClick={handleField}
                        >
                          All
                        </Dropdown.Item>
                        <Dropdown.Item
                          key='Skin'
                          id='Skin'
                          onClick={handleField}
                        >
                          Skin
                        </Dropdown.Item>
                        <Dropdown.Item
                          key='Joints'
                          id='Joints'
                          onClick={handleField}
                        >
                          Joints
                        </Dropdown.Item>
                        <Dropdown.Item
                          key='Eyes'
                          id='Eyes'
                          onClick={handleField}
                        >
                          Eyes
                        </Dropdown.Item>
                        <Dropdown.Item
                          key='Aesthetics'
                          id='Aesthetics'
                          onClick={handleField}
                        >
                          Aesthetics
                        </Dropdown.Item>
                        <Dropdown.Item
                          key='Regenerative'
                          id='Regenerative'
                          onClick={handleField}
                        >
                          Regenerative
                        </Dropdown.Item>
                        <Dropdown.Item
                          key='Neurosciences'
                          id='Neurosciences'
                          onClick={handleField}
                        >
                          Neurosciences
                        </Dropdown.Item>
                        <Dropdown.Item
                          key='Ginecology'
                          id='Ginecology'
                          onClick={handleField}
                        >
                          Ginecology
                        </Dropdown.Item>
                        <Dropdown.Item
                          key='Cardiometabolis'
                          id='Cardiometabolis'
                          onClick={handleField}
                        >
                          Cardiometabolis
                        </Dropdown.Item>
                        <Dropdown.Item
                          key='Respiratory & Anti-Infective'
                          id='Respiratory & Anti-Infective'
                          onClick={handleField}
                        >
                          Respiratory & Anti-Infective
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </Row>
                </Form>
              </div>

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '1vw',
                }}
              >
                <Button
                  style={{ width: '8vw' }}
                  className='controlPanelButton'
                  onClick={handleSubmit}
                >
                  <p style={{ marginTop: '0.1vw' }}> Update User</p>
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      ) : null
    }

    switch (serviceName) {
      case 'Create Company':
        return (
          <div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginTop: '1vw',
              }}
            >
              <Form style={{ width: '65vw' }}>
                <Row>
                  <Form.Group
                    style={{ display: 'flex', justifyContent: 'center' }}
                    as={Col}
                    controlId='name'
                  >
                    <Form.Control
                      onChange={handleDataChange}
                      style={{ width: '30vw' }}
                      placeholder='Company Name'
                      className='LoginSignupFormControl'
                    />
                  </Form.Group>
                </Row>
              </Form>
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginTop: '1vw',
              }}
            >
              <Button
                style={{ width: '8vw' }}
                className='controlPanelButton'
                onClick={handleSubmit}
              >
                <p style={{ marginTop: '0.1vw' }}> Create Company</p>
              </Button>
            </div>
          </div>
        )
      case 'Delete Company':
        return <DeleteCompany />
      case 'Update Company':
        return <UpdateCompany />
      case 'Create Course':
        return <CreateCourse />
      case 'Delete Course':
        return <DeleteCourse />
      case 'Update Course':
        return <UpdateCourse />
      case 'Create Instructor':
        return <CreateInstructor />
      case 'Delete Instructor':
        return <DeleteInstructor />
      case 'Update Instructor':
        return <UpdateInstructor />
      case 'Create User':
        return <CreateUser />
      case 'Delete User':
        return <DeleteUser />
      case 'Update User':
        return <UpdateUser />
      default:
        return null
    }
  }

  return (
    <div>
      <Header></Header>
      <table style={{ width: '100%', marginBottom: '5vw' }}>
        <tbody>
          <tr>
            <td>
              <h1 className='display-3 LoginSignupHeader m-0'>Control Panel</h1>
            </td>
          </tr>
          <tr className='myAccountBar'>
            <td>
              {/* <span
            className={
              selected === 'Companies'
                ? 'chosenOptionMyAccountSelectedResponsive'
                : 'chosenOptionMyAccountUnselectedResponsive'
            }
            onClick={() => changeSelected('Companies')}
          >
            Companies
          </span>
          <span
            style={{
              borderLeft: '0.15vw solid #9e9e9e',
              height: '1.8vw',
              width: '0.5vw',
              marginLeft: '0.5vw',
              marginRight: '0.5vw',
            }}
          ></span> */}
              <span
                className={
                  selected === 'Courses'
                    ? 'chosenOptionMyAccountSelectedResponsive'
                    : 'chosenOptionMyAccountUnselectedResponsive'
                }
                onClick={() => changeSelected('Courses')}
              >
                Courses
              </span>
              <span
                style={{
                  borderLeft: '0.15vw solid #9e9e9e',
                  height: '1.8vw',
                  width: '0.5vw',
                  marginLeft: '0.5vw',
                  marginRight: '0.5vw',
                }}
              ></span>
              <span
                className={
                  selected === 'Instructors'
                    ? 'chosenOptionMyAccountSelectedResponsive'
                    : 'chosenOptionMyAccountUnselectedResponsive'
                }
                onClick={() => changeSelected('Instructors')}
              >
                Instructors
              </span>
              <span
                style={{
                  borderLeft: '0.15vw solid #9e9e9e',
                  height: '1.8vw',
                  width: '0.5vw',
                  marginLeft: '0.5vw',
                  marginRight: '0.5vw',
                }}
              ></span>
              <span
                className={
                  selected === 'Users'
                    ? 'chosenOptionMyAccountSelectedResponsive'
                    : 'chosenOptionMyAccountUnselectedResponsive'
                }
                onClick={() => changeSelected('Users')}
              >
                Users
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <DisplayOptions />
            </td>
          </tr>
          <tr>
            <td>
              <Service />
            </td>
          </tr>
          <tr>
            <td className='successResponse'>{response}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
