import React, { useState } from 'react'
import { Modal, Form, CloseButton } from 'react-bootstrap'
import Button from '@restart/ui/esm/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Uploader from './Uploader'
import { useSnackbar } from 'notistack'

export default function ControlPanelSectionPopup(props) {
  const [questions, setQuestions] = useState(props.questions)
  const [sectionContentPath, setSectionContentPath] = useState(
    props.sectionContentPath
  )
  const [sectionName, setSectionName] = useState(props.sectionName)
  const [sectionDescription, setSectionDescription] = useState(
    props.sectionDescription
  )
  const [sectionTime, setSectionTime] = useState(props.sectionTime)
  const [assessmentPos, setAssessmentPos] = useState(props.assessmentPos)
  const [disableYoutubeUrl, setDisableYoutubeUrl] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const handleSubmit = () => {
    enqueueSnackbar(
      'Section Added/Edited. Please make sure to Create/Update the course to save changes',
      { variant: 'warning', autoHideDuration: 7000 }
    )
    let section = {
      description: sectionDescription,
      contentPath: sectionContentPath,
      name: sectionName,
      time: sectionTime,
      assessment: {
        questions: questions,
        position: assessmentPos,
      },
    }

    setQuestions([])
    setSectionContentPath('')
    setSectionName('')
    setSectionDescription('')
    setSectionTime('')
    setAssessmentPos('Choose assessment position')
    setDisableYoutubeUrl(false)
    if (props.handleAddSection) {
      props.handleAddSection(section)
    } else {
      props.handleSaveEditSection(section, props.sectionIndex)
    }
  }
  const setPath = (path) => {
    setSectionContentPath(path)
    setDisableYoutubeUrl(true)
  }

  const addMCQ = (event) => {
    setQuestions((questions) => [
      ...questions,
      {
        questionText: '',
        type: 'mcq',
        maxScore: '',
        mcqOptions: '',
        correctAnswer: '',
      },
    ])
  }
  const addShort = (event) => {
    setQuestions((questions) => [
      ...questions,
      {
        questionText: '',
        type: 'shortQuestion',
        maxScore: '',
        correctAnswer: '',
      },
    ])
  }
  const addTOF = (event) => {
    setQuestions((questions) => [
      ...questions,
      {
        questionText: '',
        type: 'trueOrFalse',
        maxScore: '',
        correctAnswer: '',
      },
    ])
  }

  const removeQuestion = (index) => {
    var array = [...questions]
    array.splice(index, 1)
    setQuestions(array)
  }
  const handleAssessmentPos = (event) => {
    setAssessmentPos(event.target.innerText)
  }

  const handleDataChange = (event) => {
    var tempQuestion = questions[event.target.getAttribute('index')]
    switch (event.target.title) {
      case 'questionText':
        tempQuestion.questionText = event.target.value
        break
      case 'maxScore':
        if (Number(event.target.value)) {
          tempQuestion.maxScore = parseInt(event.target.value)
        }
        if (event.target.value === '') {
          tempQuestion.maxScore = ''
        }
        break
      case 'mcqOptions':
        tempQuestion.mcqOptions = event.target.value
        break
      case 'correctAnswer':
        tempQuestion.correctAnswer = event.target.value
        break

      default:
        break
    }

    var array = [...questions]
    array.splice(event.target.getAttribute('index'), 1, tempQuestion)

    setQuestions(array)
  }

  const handleSectionContentPathChange = (event) => {
    setSectionContentPath(event.target.value)
  }
  const handleNameChange = (event) => {
    setSectionName(event.target.value)
  }
  const handleDescriptionChange = (event) => {
    setSectionDescription(event.target.value)
  }
  const handleTimeChange = (event) => {
    if (Number(event.target.value)) {
      setSectionTime(parseInt(event.target.value))
    }
    if (event.target.value === '') {
      setSectionTime('')
    }
  }

  const handleClose = () => {
    setQuestions([])
    setSectionContentPath('')
    setSectionName('')
    setSectionDescription('')
    setSectionTime('')
    setAssessmentPos('Choose assessment position')
    setDisableYoutubeUrl(false)
    if (props.handleAddSection) {
      props.handleCloseSection()
    } else {
      props.handleCloseEditSection()
    }
  }

  return (
    <Modal
      show={
        props.show
          ? props.show
          : props.editSectionShowIndex === props.sectionIndex
          ? true
          : false
      }
      size='lg'
      centered
      dialogClassName='Popup-modalRadius'
    >
      <Modal.Body style={{ paddingTop: '0px' }}>
        <div
          className='stickyButton'
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <CloseButton onClick={handleClose} />
        </div>
        <p
          className='courseAboutTitle'
          style={{ fontSize: '3vw', display: 'flex', justifyContent: 'center' }}
        >
          Create Section:{' '}
        </p>

        <Form style={{ width: '100%', marginBottom: '1vw' }}>
          <Form.Group
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Form.Control
              style={{ width: '30vw', marginTop: '1vw' }}
              onChange={handleNameChange}
              value={sectionName}
              placeholder='Section name'
              className='LoginSignupFormControl'
            />
            <Form.Control
              style={{ width: '30vw', marginTop: '1vw' }}
              onChange={handleDescriptionChange}
              value={sectionDescription}
              placeholder='Section description'
              className='LoginSignupFormControl'
            />
            <Form.Control
              style={{ width: '30vw', marginTop: '1vw' }}
              onChange={handleTimeChange}
              value={sectionTime}
              placeholder='Section time in minutes'
              className='LoginSignupFormControl'
            />

            <DropdownButton
              style={{ marginTop: '1vw' }}
              align={{ lg: 'start' }}
              id='field'
              title={assessmentPos}
            >
              <Dropdown.Item key='post' id='post' onClick={handleAssessmentPos}>
                Post Section
              </Dropdown.Item>
              <Dropdown.Item
                key='prepost'
                id='prepost'
                onClick={handleAssessmentPos}
              >
                Pre & Post Section
              </Dropdown.Item>
            </DropdownButton>
          </Form.Group>
        </Form>
        <p
          className='courseAboutTitle'
          style={{ fontSize: '2vw', display: 'flex', justifyContent: 'center' }}
        >
          Upload Video {/* Upload Video or Enter Youtube URL{' '} */}
        </p>

        <Uploader setPath={setPath} />
        <Form style={{ width: '100%', marginBottom: '1vw' }}>
          <Form.Group
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Form.Control
              style={{ width: '30vw', marginTop: '1vw' }}
              onChange={handleSectionContentPathChange}
              value={sectionContentPath}
              placeholder='Uploaded Video URL'
              // placeholder='Youtube URL'
              className='LoginSignupFormControl'
              disabled={true}
              // disabled={disableYoutubeUrl}
            />
          </Form.Group>
        </Form>

        {questions.map((question, index) =>
          question.type === 'mcq' ? (
            <div key={index} style={{ marginTop: '1vw' }}>
              <p
                className='courseAboutTitle'
                style={{
                  fontSize: '2vw',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Question {index + 1}{' '}
              </p>
              <p
                className='courseAboutTitle'
                style={{
                  fontSize: '1vw',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                MCQ{' '}
              </p>
              <Form style={{ width: '100%' }}>
                <Form.Group
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Form.Control
                    style={{ width: '30vw', marginTop: '1vw' }}
                    title='questionText'
                    onChange={handleDataChange}
                    index={index}
                    value={questions[index].questionText}
                    placeholder='Question'
                    className='LoginSignupFormControl'
                  />
                  <Form.Control
                    style={{ width: '30vw', marginTop: '1vw' }}
                    title='mcqOptions'
                    onChange={handleDataChange}
                    index={index}
                    value={questions[index].mcqOptions}
                    placeholder='MCQ options seperated by commas'
                    className='LoginSignupFormControl'
                  />
                  <Form.Control
                    style={{ width: '30vw', marginTop: '1vw' }}
                    title='correctAnswer'
                    onChange={handleDataChange}
                    index={index}
                    value={questions[index].correctAnswer}
                    placeholder='Correct answer'
                    className='LoginSignupFormControl'
                  />
                  <Form.Control
                    style={{ width: '30vw', marginTop: '1vw' }}
                    title='maxScore'
                    onChange={handleDataChange}
                    index={index}
                    value={questions[index].maxScore}
                    placeholder='Score'
                    className='LoginSignupFormControl'
                  />
                </Form.Group>
              </Form>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  style={{ width: '10vw', fontSize: '0.9vw' }}
                  onClick={() => removeQuestion(index)}
                  className='SubmitAnswersButton'
                >
                  {' '}
                  Remove Question {index + 1}
                </Button>
              </div>
            </div>
          ) : question.type === 'trueOrFalse' ? (
            <div key={index} style={{ marginTop: '1vw' }}>
              <p
                className='courseAboutTitle'
                style={{
                  fontSize: '2vw',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Question {index + 1}{' '}
              </p>
              <p
                className='courseAboutTitle'
                style={{
                  fontSize: '1vw',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                True or False{' '}
              </p>
              <Form style={{ width: '100%' }}>
                <Form.Group
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Form.Control
                    style={{ width: '30vw', marginTop: '1vw' }}
                    title='questionText'
                    onChange={handleDataChange}
                    index={index}
                    value={questions[index].questionText}
                    placeholder='Question'
                    className='LoginSignupFormControl'
                  />
                  <Form.Control
                    style={{ width: '30vw', marginTop: '1vw' }}
                    title='correctAnswer'
                    onChange={handleDataChange}
                    index={index}
                    value={questions[index].correctAnswer}
                    placeholder='Correct answer'
                    className='LoginSignupFormControl'
                  />
                  <Form.Control
                    style={{ width: '30vw', marginTop: '1vw' }}
                    title='maxScore'
                    onChange={handleDataChange}
                    index={index}
                    value={questions[index].maxScore}
                    placeholder='Score'
                    className='LoginSignupFormControl'
                  />
                </Form.Group>
              </Form>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  style={{ width: '10vw', fontSize: '0.9vw' }}
                  onClick={() => removeQuestion(index)}
                  className='SubmitAnswersButton'
                >
                  {' '}
                  Remove Question {index + 1}
                </Button>
              </div>
            </div>
          ) : (
            <div key={index} style={{ marginTop: '1vw' }}>
              <p
                className='courseAboutTitle'
                style={{
                  fontSize: '2vw',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Question {index + 1}{' '}
              </p>
              <p
                className='courseAboutTitle'
                style={{
                  fontSize: '1vw',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Short Question{' '}
              </p>
              <Form style={{ width: '100%' }}>
                <Form.Group
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Form.Control
                    style={{ width: '30vw', marginTop: '1vw' }}
                    title='questionText'
                    onChange={handleDataChange}
                    index={index}
                    value={questions[index].questionText}
                    placeholder='Question'
                    className='LoginSignupFormControl'
                  />
                  <Form.Control
                    style={{ width: '30vw', marginTop: '1vw' }}
                    title='correctAnswer'
                    onChange={handleDataChange}
                    index={index}
                    value={questions[index].correctAnswer}
                    placeholder='Correct answer'
                    className='LoginSignupFormControl'
                  />
                  <Form.Control
                    style={{ width: '30vw', marginTop: '1vw' }}
                    title='maxScore'
                    onChange={handleDataChange}
                    index={index}
                    value={questions[index].maxScore}
                    placeholder='Score'
                    className='LoginSignupFormControl'
                  />
                </Form.Group>
              </Form>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  style={{ width: '10vw', fontSize: '0.9vw' }}
                  onClick={() => removeQuestion(index)}
                  className='SubmitAnswersButton'
                >
                  {' '}
                  Remove Question {index + 1}
                </Button>
              </div>
            </div>
          )
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1vw',
          }}
        >
          <Button
            style={{ width: '10vw', fontSize: '0.9vw' }}
            onClick={addMCQ}
            className='SubmitAnswersButton'
          >
            {' '}
            Add MCQ Question
          </Button>
          <Button
            style={{
              width: '10vw',
              fontSize: '0.9vw',
              marginLeft: '1vw',
              marginRight: '1vw',
            }}
            onClick={addShort}
            className='SubmitAnswersButton'
          >
            {' '}
            Add Short Question
          </Button>
          <Button
            style={{ width: '10vw', fontSize: '0.9vw' }}
            onClick={addTOF}
            className='SubmitAnswersButton'
          >
            {' '}
            Add True or False Question
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {props.show ? (
            <Button
              style={{ width: '12vw' }}
              onClick={handleSubmit}
              className='SubmitAnswersButton'
            >
              {' '}
              Create Section
            </Button>
          ) : (
            <Button
              style={{ width: '12vw' }}
              onClick={handleSubmit}
              className='SubmitAnswersButton'
            >
              {' '}
              Edit Section
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}
