import React, { useCallback, useState, useEffect } from 'react'
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import axios from 'axios'
import { backendLink } from '../keys_dev'

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + outerRadius / 3) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy='2.5%'
        textAnchor='middle'
        fill={fill}
        style={{ fontSize: '0.75vw' }}
      >
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + outerRadius / 15}
        outerRadius={outerRadius + outerRadius / 7.5}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill='#333'
        style={{ fontSize: '0.8vw' }}
      >{`Users: ${value}`}</text>
      <text
        style={{ fontSize: '0.8vw' }}
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill='#999'
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}

export default function PieChartComponent(props) {
  const [activeIndex, setActiveIndex] = useState(0)
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )
  const [courseSelected, setCourseSelected] = useState({
    name: props.courses[0].name,
    sections: props.courses[0].sections,
    id: props.courses[0]._id,
  })
  const [sectionsSelected, setSectionsSelected] = useState({
    name: 'Section 1',
    index: 0,
  })
  const [data, setData] = useState(props.data)
  useEffect(() => {
    setData(props.data)
  }, [props.data])
  const handleChangeSelectCourse = (event) => {
    console.log(event.target.title)
    setCourseSelected({
      name: event.target.outerText,
      sections: props.courses[event.target.title].sections,
      id: event.target.id,
    })
    setSectionsSelected({
      name: 'Section 1',
      index: 0,
    })
    axios({
      url: `${backendLink}/api/v1/analytics/getNumberOfCompletionsPerAssessment`,
      method: 'POST',
      data: {
        courseId: event.target.id,
        sectionIndex: sectionsSelected.index,
      },
    })
      .then((res) => {
        console.log(res.data)
        setData([
          {
            name: 'Completed',
            value: res.data.completed,
          },
          {
            name: 'Did not complete',
            value: res.data.notCompleted,
          },
        ])
      })
      .catch((err) => {
        setData([])
        console.log(err)
      })
  }
  const handleChangeSelectSection = (event) => {
    setSectionsSelected({
      name: event.target.outerText,
      index: event.target.id,
    })
    axios({
      url: `${backendLink}/api/v1/analytics/getNumberOfCompletionsPerAssessment`,
      method: 'POST',
      data: {
        courseId: courseSelected.id,
        sectionIndex: event.target.id,
      },
    })
      .then((res) => {
        setData([
          {
            name: 'Completed',
            value: res.data.completed,
          },
          {
            name: 'Did not complete',
            value: res.data.notCompleted,
          },
        ])
      })
      .catch((err) => {
        console.log(err)
      })
  }
  // s
  return props.data.length > 0 ? (
    <div
      style={{
        width: props.width,
        height: props.height,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        overflow: 'auto',
      }}
    >
      <p
        className='courseAboutTitle'
        style={{
          fontSize: '1vw',
          textAlign: 'center',
          width: '80%',
        }}
      >
        {props.label}
      </p>
      <div style={{ display: 'flex' }}>
        <DropdownButton
          align={{ lg: 'start' }}
          id='dropdown-basic-button'
          title={courseSelected.name}
          style={{ marginRight: '1vw' }}
        >
          {props.courses &&
            props.courses.map((course, index) => (
              <Dropdown.Item
                title={index}
                id={course._id}
                key={course._id}
                onClick={handleChangeSelectCourse}
              >
                {course.name}
              </Dropdown.Item>
            ))}
        </DropdownButton>

        <DropdownButton
          align={{ lg: 'start' }}
          id='dropdown-basic-button'
          title={sectionsSelected.name}
        >
          {courseSelected.sections &&
            courseSelected.sections.map((section, index) => (
              <Dropdown.Item
                key={index}
                id={index}
                onClick={handleChangeSelectSection}
              >
                {'Section ' + (index + 1)}
              </Dropdown.Item>
            ))}
        </DropdownButton>
      </div>

      <ResponsiveContainer width='100%' height='100%'>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            innerRadius='50%'
            outerRadius='65%'
            fill='#083c64'
            dataKey='value'
            onMouseEnter={onPieEnter}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  ) : null
}
