import React, { useState, useEffect } from 'react'
import { backendLink } from '../keys_dev'
import { Container, Row, Col, Accordion, Spinner } from 'react-bootstrap'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import CourseCard from '../Components/CourseCard'
import CourseFilter from '../Components/CourseFilter'
import LineChartComponent from '../Components/LineChartComponent'
import Header from '../Components/Header'
import axios from 'axios'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  PDFDownloadLink,
  Document,
  Page, // eslint-disable-next-line
} from '@react-pdf/renderer'
import Html from 'react-pdf-html'
import { T } from '../Languages/LanguageHandler'
export default function MyProfile() {
  const dict = T()
  const userId = useSelector((state) => state.id)
  const [selected, setSelected] = useState('My Courses')
  const [enrolledCourses, setEnrolledCourses] = useState([])
  const [filteredCourses, setFilteredCourses] = useState([])
  const [userScores, setUserScores] = useState()
  const [category, setCategory] = useState('')
  const [name, setName] = useState('')

  const changeSelected = (newSelection) => {
    setSelected(newSelection)
  }

  const applyFilter = () => {
    let tempfilteredCourses = enrolledCourses.filter(function (e) {
      return (
        e.courseName.toLowerCase().includes(name.toLowerCase()) &&
        e.courseField.toLowerCase().includes(category.toLowerCase())
      )
    })

    setFilteredCourses(tempfilteredCourses)
  }

  useEffect(() => {
    applyFilter()
  }, [name, category]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    axios({
      url: `${backendLink}/api/v1/user/${userId}`,
      method: 'GET',
    })
      .then((res) => {
        setEnrolledCourses(res.data.user.enrolledCourses)
        setFilteredCourses(res.data.user.enrolledCourses)
      })
      .catch((err) => {
        console.log(err)
      })

    axios({
      url: `${backendLink}/api/v1/analytics/getUserCourseCompletions`,
      method: 'POST',
      data: { userId: userId },
    })
      .then((res) => {
        console.log(res.data.userCourseData[0])
        setUserScores(res.data.userCourseData[0])
      })
      .catch((err) => {
        console.log(err)
      })
  }, [userId])

  function DisplayEnrolledCourses() {
    if (filteredCourses.length !== 0) {
      return filteredCourses.map((course, index) => (
        <Col
          style={{
            marginBottom: '5%',
          }}
          xs={12}
          md={6}
          lg={4}
          className='centerContent'
          key={index}
        >
          <CourseCard
            courseViews={course.courseViews}
            courseId={course.courseId}
            courseField={course.courseField}
            courseMinsTaken={course.courseMinsTaken}
            courseName={course.courseName}
          ></CourseCard>
        </Col>
      ))
    } else {
      return null
    }
  }

  function DisplayUserCourseCompletions() {
    function CustomTogglePerCourse({ course, eventKey, index }) {
      const decoratedOnClick = useAccordionButton(eventKey)
      return (
        <Row
          onClick={decoratedOnClick}
          key={course.courseName + index}
          style={{ marginLeft: '10%', width: '80%' }}
          className='myScoreContainerHover'
        >
          <Col> {course.courseName}</Col>
          <Col>{course.percentageDone.toFixed(2)}%</Col>
          <Col>
            {dict['Enrolled at:']}{' '}
            {course.dateOfEnrollment
              ? moment(course.dateOfEnrollment).format('DD/MM/YY')
              : null}
          </Col>
          <Col>
            {course.dateOfCompletion ? (
              <p>
                {dict['Completed at:']}{' '}
                {moment(course.dateOfCompletion).format('DD/MM/YY')}
              </p>
            ) : (
              <p>{dict['Not Completed']}</p>
            )}
          </Col>
        </Row>
      )
    }

    function AccordionExpansionPerCourse({ userScores, course }) {
      return userScores ? (
        <Row style={{ marginLeft: '25%', width: '100%' }}>
          <LineChartComponent
            width='50%'
            height='200px'
            userData={userScores}
            course={course}
          />
        </Row>
      ) : (
        <Row style={{ width: '100%%', justifyContent: 'center' }}>
          LOADING...
        </Row>
      )
    }
    function AccordionExpansion({ enrolledCoursesData, userScores }) {
      return (
        <div>
          <Row
            style={{ marginLeft: '10%', width: '80%' }}
            className='myScoreContainerFirstRow'
          >
            <Col>{dict['Course Name']}</Col>
            <Col>{dict['Percentage Done']}</Col>
            <Col>{dict['Date of Enrollment']}</Col>
            <Col>{dict['Completion']}</Col>
          </Row>
          {enrolledCoursesData.map((course, index) => (
            <Accordion key={index} flush>
              <CustomTogglePerCourse
                eventKey='0'
                course={course}
                index={index}
              ></CustomTogglePerCourse>

              <Accordion.Collapse
                className='myScoreContainerCollapsed'
                eventKey='0'
              >
                <AccordionExpansionPerCourse
                  course={course}
                  userScores={userScores}
                ></AccordionExpansionPerCourse>
              </Accordion.Collapse>
            </Accordion>
          ))}
        </div>
      )
    }
    return userScores ? (
      <div>
        <AccordionExpansion
          enrolledCoursesData={userScores.enrolledCoursesData}
          userScores={userScores}
        ></AccordionExpansion>
      </div>
    ) : (
      <div style={{ marginTop: '1vw' }} className='centerContent'>
        <Spinner animation='border' />
      </div>
    )
  }

  const html = `<html>
  <body>
    <style>
      .my-heading4 {
        background: darkgreen;
        color: white;
      }
      pre {
        background-color: #eee;
        padding: 10px;
      }
    </style>
    <h1>Heading 1</h1>
    <h2 style="background-color: pink">Heading 2</h2>
    <h3>Heading 3</h3>
    <h4 class="my-heading4">Heading 4</h4>
    <p>
      Paragraph with <strong>bold</strong>, <i>italic</i>, <u>underline</u>,
      <s>strikethrough</s>,
      <strong><u><s><i>and all of the above</i></s></u></strong>
    </p>
    <p>
      Paragraph with image  and
      <a href="http://google.com">link</a>
    </p>
    <hr />
    <ul>
      <li>Unordered item</li>
      <li>Unordered item</li>
    </ul>
    <ol>
      <li>Ordered item</li>
      <li>Ordered item</li>
    </ol>
    <br /><br /><br /><br /><br />
    Text outside of any tags
    <table>
      <thead>
        <tr>
          <th>Column 1</th>
          <th>Column 2</th>
          <th>Column 3</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Foo</td>
          <td>Bar</td>
          <td>Foobar</td>
        </tr>
        <tr>
          <td colspan="2">Foo</td>
          <td>Bar</td>
        </tr>
        <tr>
          <td>Some longer thing</td>
          <td>Even more content than before!</td>
          <td>Even more content than before!</td>
        </tr>
      </tbody>
    </table>
  </body>
</html>
`
  // Create Document Component
  const MyDocument = () => (
    <Document>
      <Page>
        <Html>{html}</Html>
      </Page>
    </Document>
  )
  return (
    <Container fluid className='p-0 noOverflow'>
      <Header></Header>
      <Row>
        <h1 className='display-3 LoginSignupHeader m-0'>
          {dict['My account']}
        </h1>
      </Row>
      <table style={{ width: '100%', marginBottom: '2%' }}>
        <tbody>
          <tr className='myAccountBarResponsive'>
            <td>
              <span
                className={
                  selected === 'My Courses'
                    ? 'chosenOptionMyAccountSelectedResponsive'
                    : 'chosenOptionMyAccountUnselectedResponsive'
                }
                onClick={() => changeSelected('My Courses')}
              >
                {dict['My Courses']}
              </span>
              <span
                style={{
                  borderLeft: '0.15vw solid #9e9e9e',
                  //   height: '1%',
                  width: '100%',
                  marginLeft: '0.5vw',
                  marginRight: '0.5vw',
                }}
              ></span>
              <span
                className={
                  selected === 'Scores'
                    ? 'chosenOptionMyAccountSelectedResponsive'
                    : 'chosenOptionMyAccountUnselectedResponsive'
                }
                onClick={() => changeSelected('Scores')}
              >
                {dict['Scores']}
              </span>
              <span
                style={{
                  borderLeft: '0.15vw solid #9e9e9e',
                  height: '1.8vw',
                  width: '0.5vw',
                  marginLeft: '0.5vw',
                  marginRight: '0.5vw',
                }}
              ></span>
              <span
                className={
                  selected === 'Certificates'
                    ? 'chosenOptionMyAccountSelectedResponsive'
                    : 'chosenOptionMyAccountUnselectedResponsive'
                }
                onClick={() => changeSelected('Certificates')}
              >
                {dict['Certificates']}
              </span>
              <span
                style={{
                  borderLeft: '0.15vw solid #9e9e9e',
                  height: '1.8vw',
                  width: '0.5vw',
                  marginLeft: '0.5vw',
                  marginRight: '0.5vw',
                }}
              ></span>
              <span
                className={
                  selected === 'Account Details'
                    ? 'chosenOptionMyAccountSelectedResponsive'
                    : 'chosenOptionMyAccountUnselectedResponsive'
                }
                onClick={() => changeSelected('Account Details')}
              >
                {dict['Account details']}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      {selected === 'My Courses' && (
        <div>
          <Row className='centerContent' style={{ marginBottom: '2%' }}>
            <CourseFilter
              setName={setName}
              setCategory={setCategory}
            ></CourseFilter>
          </Row>
          <Row className='ResponsiveCoursesContainer'>
            <DisplayEnrolledCourses />
          </Row>
        </div>
      )}

      {selected === 'Certificates' && (
        <div>
          <PDFDownloadLink
            document={<MyDocument />}
            fileName='Certificate Test.pdf'
          >
            {({ blob, url, loading, error }) =>
              loading ? 'Loading document...' : 'Download now!'
            }
          </PDFDownloadLink>
        </div>
      )}

      {selected === 'Scores' && (
        <div>
          <Container className='myScoreContainer'>
            <DisplayUserCourseCompletions />
          </Container>
        </div>
      )}
    </Container>
  )
}
