import React, { useState, useEffect, useRef } from 'react'
import ReactToPrint from 'react-to-print'
import moment from 'moment'
import Header from '../Components/Header'
import ExportExcel from '../Components/ExportExcel'
import { Container, Row, Col, Accordion, Spinner } from 'react-bootstrap'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import DropdownButton from 'react-bootstrap/DropdownButton'
import TextField from '@mui/material/TextField'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import IconButton from '@mui/material/IconButton'
import TooltipMUI from '@mui/material/Tooltip'
import Dropdown from 'react-bootstrap/Dropdown'
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from 'recharts'
import axios from 'axios'
import { backendLink } from '../keys_dev'
import { countries, fields } from '../enums'
import PieChartComponent from '../Components/PieChartComponent'
import LineChartComponent from '../Components/LineChartComponent'
import { ReactComponent as Users } from '../images/users.svg'
import { ReactComponent as Dashboard } from '../images/dashboard.svg'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

export default function Analytics() {
  const userId = useSelector((state) => state.id)
  const isLineManager = useSelector((state) => state.isLineManager)
  const history = useHistory()
  var pathArray = window.location.pathname.split('/')
  const [
    FieldsViewsAndEnrolledBarChartData,
    setFieldsViewsAndEnrolledBarChartData,
  ] = useState([])
  const [
    CoursesViewsAndEnrolledBarChartData,
    setCoursesViewsAndEnrolledBarChartData,
  ] = useState([])
  const [CourseQuestionData, setCourseQuestionData] = useState([])
  const [SelectedCourseQuestionData, setSelectedCourseQuestionData] =
    useState('')
  const [CourseSectionCompletions, setCourseSectionCompletions] = useState([])
  const [CourseViews, setCourseViews] = useState('')
  const [CourseEnrollments, setCourseEnrollments] = useState('')
  const [usersSignedUp, setUsersSignedUp] = useState('')
  const [courses, setCourses] = useState()
  const [userData, setUserData] = useState()
  const [userAccountsData, setUserAccountsData] = useState()
  const [userAccountsDataRaw, setUserAccountsDataRaw] = useState()
  const [accordionExpansion, setAccordionExpansion] = useState('1')
  const changeSelected = (input) => {
    history.push('/analytics/' + input)
    // history.go(0)
  }
  useEffect(() => {
    if (!pathArray[2]) {
      window.history.pushState('', '', '/analytics/dashboard')
    }
  }, [pathArray])

  useEffect(() => {
    if (pathArray[2] === 'dashboard') {
      // GET FIELDS DATA
      axios({
        url: `${backendLink}/api/v1/analytics/getCoursesAnalytics`,
        method: 'POST',
      })
        .then((res) => {
          const fieldData = []
          fieldData.push({
            name: 'Wounds',
            views: res.data.analyticsPerField.wounds.views,
            enrolled: res.data.analyticsPerField.wounds.enrolled,
          })
          fieldData.push({
            name: 'Joints',
            views: res.data.analyticsPerField.joints.views,
            enrolled: res.data.analyticsPerField.joints.enrolled,
          })
          fieldData.push({
            name: 'Eyes',
            views: res.data.analyticsPerField.eyes.views,
            enrolled: res.data.analyticsPerField.eyes.enrolled,
          })
          setFieldsViewsAndEnrolledBarChartData(fieldData)
          setCoursesViewsAndEnrolledBarChartData(res.data.analyticsPerCourse)
          setCourseViews(res.data.totalAnalytics.views)
          setCourseEnrollments(res.data.totalAnalytics.enrolled)
          setUsersSignedUp(res.data.totalAnalytics.usersSignedUp)
        })
        .catch((err) => {
          console.log(err)
        })

      //GET COURSES FOR DROPDOWN BUTTONS
      axios({
        url: `${backendLink}/api/v1/course/getCourses`,
        method: 'POST',
      })
        .then((res) => {
          const tempCourses = res.data.courses
          setCourses(tempCourses)
          setSelectedCourseQuestionData(tempCourses[0].name)
          axios({
            url: `${backendLink}/api/v1/analytics/getQuestionAnalytics`,
            method: 'POST',
            data: {
              courseId: tempCourses[0]._id,
            },
          })
            .then((res) => {
              const questionData = []
              res.data.data.forEach((question) => {
                questionData.push({
                  name: 'S' + question.section + 'Q' + question.question,
                  averageScore: question.averageScore,
                })
              })
              setCourseQuestionData(questionData)
            })
            .catch((err) => {
              console.log(err)
            })
          axios({
            url: `${backendLink}/api/v1/analytics/getNumberOfCompletionsPerAssessment`,
            method: 'POST',
            data: {
              courseId: tempCourses[0]._id,
              sectionIndex: 0,
            },
          })
            .then((res) => {
              setCourseSectionCompletions([
                {
                  name: 'Completed',
                  value: res.data.completed,
                },
                {
                  name: 'Did not complete',
                  value: res.data.notCompleted,
                },
              ])
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          console.log(err)
        })
    }

    if (pathArray[2] === 'users') {
      if (pathArray[3] === 'courses') {
        //Get USER COURSE COMPLETION DATA
        let data = {}
        if (isLineManager) {
          data.lineManagerId = userId
        }
        axios({
          url: `${backendLink}/api/v1/analytics/getUserCourseCompletions`,
          method: 'POST',
          data: data,
        })
          .then((res) => {
            console.log(res.data.userCourseData)
            setUserData(res.data.userCourseData)
          })
          .catch((err) => {
            console.log(err)
          })
      }
      if (pathArray[3] === 'accounts') {
        let data = {}
        if (isLineManager) {
          data.lineManagerId = userId
        }
        axios({
          url: `${backendLink}/api/v1/user/getUsers`,
          method: 'POST',
          data: data,
        })
          .then((res) => {
            setUserAccountsData(res.data.users)
            setUserAccountsDataRaw(res.data.users)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }, [pathArray[2], pathArray[3]])

  const handleCourseSelectQuestionAssessment = (event) => {
    setSelectedCourseQuestionData(event.target.outerText)
    axios({
      url: `${backendLink}/api/v1/analytics/getQuestionAnalytics`,
      method: 'POST',
      data: {
        courseId: event.target.id,
      },
    })
      .then((res) => {
        const questionData = []
        res.data.data.forEach((question) => {
          questionData.push({
            name: 'S' + question.section + 'Q' + question.question,
            averageScore: question.averageScore,
          })
        })
        setCourseQuestionData(questionData)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function CoursesViewsAndEnrolledBarChart(props) {
    const [currentData, setCurrentData] = useState(
      props.data.filter(
        (x) => x.country == countries[0] && x.field == fields[0]
      )
    )
    const [currentCountry, setCurrentCountry] = useState(countries[0])
    const [currentField, setCurrentField] = useState(fields[0])
    const handleChangeCountry = (e) => {
      setCurrentCountry(e.target.outerText)
      setCurrentData(
        props.data.filter(
          (x) => x.country == e.target.outerText && x.field == currentField
        )
      )
    }
    const handleChangeField = (e) => {
      setCurrentField(e.target.outerText)
      setCurrentData(
        props.data.filter(
          (x) => x.country == currentCountry && x.field == e.target.outerText
        )
      )
    }
    return (
      currentData && (
        <div
          style={{
            width: props.width,
            height: props.height,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <p
            className='courseAboutTitle'
            style={{
              paddingLeft: '1.5vw',
              fontSize: '1vw',
              textAlign: 'center',
              width: '80%',
            }}
          >
            {props.label}
          </p>
          <div
            style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <DropdownButton
              align={{ lg: 'start' }}
              id='dropdown-basic-button'
              title={currentCountry}
              style={{ margin: '10px' }}
            >
              {countries.map((country) => (
                <Dropdown.Item
                  key={country}
                  id={country}
                  onClick={handleChangeCountry}
                >
                  {country}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <DropdownButton
              align={{ lg: 'start' }}
              id='dropdown-basic-button'
              title={currentField}
              style={{ margin: '10px' }}
            >
              {fields.map((field) => (
                <Dropdown.Item
                  key={field}
                  id={field}
                  onClick={handleChangeField}
                >
                  {field}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart data={currentData}>
              <CartesianGrid strokeDasharray='5 5' vertical={false} />
              <XAxis
                dataKey='name'
                tick={{
                  fontSize: '15.5px',
                  fontWeight: '600',
                  overflowWrap: 'break-word',
                  textAnchor: 'end',
                }}
                angle={props.angle}
                height={props.Xheight}
                width={300}
                interval={0}
                padding={{ right: props.padding }}
                dy={props.dy}
                dx={props.dx}
              />
              <YAxis tick={{ fontSize: '0.9vw' }} />
              <Tooltip />
              {/* <Legend /> */}
              <Bar dataKey={props.dataKey} fill='#083c64' />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )
    )
  }
  function CoursesViewsAndEnrolledBarChartVertical(props) {
    const [currentData, setCurrentData] = useState(
      props.data.filter(
        (x) => x.country == countries[0] && x.field == fields[0]
      )
    )
    const [currentCountry, setCurrentCountry] = useState(countries[0])
    const [currentField, setCurrentField] = useState(fields[0])
    const handleChangeCountry = (e) => {
      setCurrentCountry(e.target.outerText)
      setCurrentData(
        props.data.filter(
          (x) => x.country == e.target.outerText && x.field == currentField
        )
      )
    }
    const handleChangeField = (e) => {
      setCurrentField(e.target.outerText)
      setCurrentData(
        props.data.filter(
          (x) => x.country == currentCountry && x.field == e.target.outerText
        )
      )
    }
    return (
      currentData && (
        <div
          style={{
            width: props.width,
            height: `${currentData.length * 50 + 200}px`,
            // height: props.height,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <p
            className='courseAboutTitle'
            style={{
              paddingLeft: '1.5vw',
              fontSize: '1vw',
              textAlign: 'center',
              width: '80%',
            }}
          >
            {props.label}
          </p>
          <div
            style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <DropdownButton
              align={{ lg: 'start' }}
              id='dropdown-basic-button'
              title={currentCountry}
              style={{ margin: '10px' }}
            >
              {countries.map((country) => (
                <Dropdown.Item
                  key={country}
                  id={country}
                  onClick={handleChangeCountry}
                >
                  {country}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <DropdownButton
              align={{ lg: 'start' }}
              id='dropdown-basic-button'
              title={currentField}
              style={{ margin: '10px' }}
            >
              {fields.map((field) => (
                <Dropdown.Item
                  key={field}
                  id={field}
                  onClick={handleChangeField}
                >
                  {field}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>

          <ResponsiveContainer width='100%' height='100%'>
            <BarChart data={currentData} layout='vertical'>
              <CartesianGrid strokeDasharray='5 5' />
              <XAxis type='number' tick={{ fontSize: '0.9vw' }} />
              <YAxis
                type='category'
                dataKey='name'
                tick={{
                  fontSize: '0.7vw',
                  fontWeight: '600',
                  overflowWrap: 'break-word',
                }}
                width={200}
                interval={0}
              />
              <Tooltip />
              {/* <Legend /> */}
              <Bar dataKey={props.dataKey} fill='#083c64' />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )
    )
  }
  function RadarChar(props) {
    const data = props.data
    return (
      <div
        style={{
          width: props.width,
          height: props.height,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <DropdownButton
          align={{ lg: 'start' }}
          id='dropdown-basic-button'
          title={SelectedCourseQuestionData}
        >
          {courses &&
            courses.map((course) => (
              <Dropdown.Item
                key={course._id}
                id={course._id}
                onClick={handleCourseSelectQuestionAssessment}
              >
                {course.name}
              </Dropdown.Item>
            ))}
        </DropdownButton>
        <p
          className='courseAboutTitle'
          style={{
            fontSize: '1vw',
            textAlign: 'center',
            width: '80%',
          }}
        >
          {props.label}
        </p>
        {data.length > 0 ? (
          <ResponsiveContainer width='100%' height='100%'>
            <RadarChart cx='50%' cy='50%' outerRadius='80%' data={data}>
              <PolarGrid />
              <PolarAngleAxis tick={{ fontSize: '0.9vw' }} dataKey='name' />
              <PolarRadiusAxis tick={{ fontSize: '0.9vw' }} />
              <Radar
                dataKey='averageScore'
                stroke='#083c64'
                fill='#083c64'
                fillOpacity={0.6}
              />
            </RadarChart>
          </ResponsiveContainer>
        ) : null}
      </div>
    )
  }

  function DisplayUserCourseCompletions({ inputDefault }) {
    function CustomToggle({ eventKey, userData }) {
      const decoratedOnClick = useAccordionButton(eventKey)
      return (
        <Row
          onClick={decoratedOnClick}
          className='analyticUserPageContainerHover'
        >
          <Col>{userData.userName}</Col>
          <Col>{userData.numberOfCoursesCompleted}</Col>
          <Col>{userData.numberOfCoursesEnrolled}</Col>
        </Row>
      )
    }
    function CustomTogglePerCourse({ course, eventKey, index }) {
      const decoratedOnClick = useAccordionButton(eventKey)
      return (
        <Row
          onClick={decoratedOnClick}
          key={course.courseName + index}
          style={{ marginLeft: '10%', width: '80%' }}
          className='analyticUserPageContainerHover'
        >
          <Col style={{ paddingLeft: '0.5vw', width: '25%' }}>
            {' '}
            {course.courseName}
          </Col>
          <Col style={{ paddingLeft: '0.5vw' }}>
            {course.percentageDone.toFixed(2)}%
          </Col>
          {/* <Col style={{ paddingLeft: '0.5vw' }}>
            {course.completed ? 'Completed' : 'Not completed'}
          </Col> */}
          <Col style={{ paddingLeft: '0.5vw' }}>
            Enrolled at:{' '}
            {course.dateOfEnrollment
              ? moment(course.dateOfEnrollment).format('DD/MM/YY')
              : null}
          </Col>
          <Col style={{ paddingLeft: '0.5vw' }}>
            {course.dateOfCompletion ? (
              <p>
                Completed at:{' '}
                {moment(course.dateOfCompletion).format('DD/MM/YY')}
              </p>
            ) : (
              <p>Not Completed</p>
            )}
          </Col>
        </Row>
      )
    }

    function AccordionExpansionPerCourse({ userData, course }) {
      return userData ? (
        <Row style={{ marginLeft: '25%', width: '100%' }}>
          <LineChartComponent
            width='50%'
            height='200px'
            userData={userData}
            course={course}
          />
        </Row>
      ) : (
        <Row style={{ width: '100%%', justifyContent: 'center' }}>
          LOADING...
        </Row>
      )
    }
    function AccordionExpansion({ enrolledCoursesData, userData }) {
      return enrolledCoursesData.map((course, index) => (
        <Accordion key={index} flush>
          <CustomTogglePerCourse
            eventKey='0'
            course={course}
            index={index}
          ></CustomTogglePerCourse>

          <Accordion.Collapse
            className='analyticUserPageContainerCollapsed'
            eventKey='0'
          >
            <AccordionExpansionPerCourse
              course={course}
              userData={userData}
            ></AccordionExpansionPerCourse>
          </Accordion.Collapse>
        </Accordion>
      ))
    }
    return userData ? (
      <div>
        <Row className='analyticsUserPageFirstRow'>
          <Col>Name</Col>
          <Col># of courses completed</Col>
          <Col># of courses enrolled</Col>
        </Row>

        {userData.map((userData, index) => (
          <Accordion defaultActiveKey={inputDefault} key={index} flush>
            <CustomToggle eventKey='0' userData={userData}></CustomToggle>

            <Accordion.Collapse
              className='analyticUserPageContainerCollapsed'
              eventKey='0'
            >
              <AccordionExpansion
                enrolledCoursesData={userData.enrolledCoursesData}
                userData={userData}
              ></AccordionExpansion>
            </Accordion.Collapse>
          </Accordion>
        ))}
      </div>
    ) : (
      <div style={{ marginTop: '1vw' }} className='centerContent'>
        <Spinner animation='border' />
      </div>
    )
  }

  function DisplayUserAccounts() {
    return userAccountsData ? (
      <div>
        <Row className='analyticsUserPageFirstRow'>
          <Col style={{ maxWidth: '35%' }}>Name</Col>
          <Col style={{ maxWidth: '35%' }}>E-mail</Col>
          <Col style={{ maxWidth: '15%' }}>Country</Col>
          <Col style={{ maxWidth: '15%' }}>Field</Col>
        </Row>

        {userAccountsData.map((user) => (
          <Row className='analyticUserPageContainerHover' key={user._id}>
            <Col style={{ maxWidth: '35%' }}>{user.name}</Col>
            <Col style={{ maxWidth: '35%' }}>{user.email}</Col>
            <Col style={{ maxWidth: '15%' }}>{user.country}</Col>
            <Col style={{ maxWidth: '15%' }}>{user.field}</Col>
          </Row>
        ))}
      </div>
    ) : (
      <div style={{ marginTop: '1vw' }} className='centerContent'>
        <Spinner animation='border' />
      </div>
    )
  }

  const setExpanded = async () => {
    await setAccordionExpansion('0')
  }
  const setCollapsed = async () => {
    if (accordionExpansion === '1') {
      await setAccordionExpansion('2')
    } else {
      await setAccordionExpansion('1')
    }
  }
  const PrintCollapsed = () => {
    return (
      <div>
        <ReactToPrint
          onBeforeGetContent={() => setCollapsed()}
          trigger={() => <button>Print collapsed</button>}
          content={() => componentRef.current}
        />
      </div>
    )
  }
  const PrintDetailed = () => {
    return (
      <div>
        <ReactToPrint
          onBeforeGetContent={() => setExpanded()}
          trigger={() => <button>Print detailed</button>}
          content={() => componentRef.current}
          onAfterPrint={() => setCollapsed()}
        />
      </div>
    )
  }
  const Print = () => {
    return (
      <div>
        <ReactToPrint
          trigger={() => <button>Print</button>}
          content={() => componentRef.current}
        />
      </div>
    )
  }
  const PrintAccountDetails = () => {
    return (
      <div>
        <ReactToPrint
          trigger={() => <button>Print/Save PDF</button>}
          content={() => accountDetailsRef.current}
        />
      </div>
    )
  }

  const [filters, setFilters] = useState({ search: '', country: '', field: '' })
  useEffect(() => {
    applyFilter()
  }, [filters])
  const handleFilterChange = (event) => {
    if (event.target.innerText)
      setFilters((data) => ({
        ...data,
        [event.target.id]: event.target.innerText,
      }))
    else
      setFilters((data) => ({ ...data, [event.target.id]: event.target.value }))
  }
  const applyFilter = () => {
    console.log(filters)
    if (userAccountsDataRaw) {
      let tempfilteredUsers = userAccountsDataRaw.filter(function (x) {
        return (
          (x.name.toLowerCase().includes(filters.search.toLowerCase()) ||
            x.email.toLowerCase().includes(filters.search.toLowerCase()) ||
            x.country.toLowerCase().includes(filters.search.toLowerCase()) ||
            x.field.toLowerCase().includes(filters.search.toLowerCase())) &&
          x.country.toLowerCase().includes(filters.country.toLowerCase()) &&
          x.field.toLowerCase().includes(filters.field.toLowerCase())
        )
      })
      setUserAccountsData(tempfilteredUsers)
    }
  }
  const resetFilters = () => {
    setFilters({ search: '', country: '', field: '' })
  }
  const componentRef = useRef()
  const accountDetailsRef = useRef()

  return (
    <div>
      <Header></Header>

      <div style={{ display: 'flex' }}>
        <div className='analyticsSideBar'>
          <ul>
            <li>
              <div
                className={
                  pathArray[2] === 'dashboard'
                    ? 'analyticsSideBarSelected'
                    : null
                }
                onClick={() => changeSelected('dashboard')}
              >
                <Dashboard
                  style={{
                    width: '1.5vw',
                    marginTop: '-0.5vw',
                    marginRight: '0.5vw',
                  }}
                ></Dashboard>
                <span>Dashboard</span>
              </div>
            </li>
            <li>
              <div
                className={
                  pathArray[2] === 'users' ? 'analyticsSideBarSelected' : null
                }
                onClick={() => changeSelected('users/courses')}
              >
                <Users
                  style={{
                    width: '1.5vw',
                    marginTop: '-0.3vw',
                    marginRight: '0.5vw',
                  }}
                ></Users>
                <span>Users</span>
              </div>
            </li>
          </ul>
        </div>

        {pathArray[2] === 'dashboard' && (
          <Container className='analyticPageContainer'>
            <Row md={3} xs={1}>
              <Col>
                <div className='analyticsCard'>
                  <p>{CourseViews.toLocaleString()}</p>
                  <p>Course Views</p>
                </div>
              </Col>
              <Col>
                <div className='analyticsCard'>
                  <p>{usersSignedUp.toLocaleString()}</p>
                  <p>Users signed up</p>
                </div>
              </Col>
              <Col>
                <div className='analyticsCard'>
                  <p>{CourseEnrollments.toLocaleString()}</p>
                  <p>Course enrollments</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  {/* <CoursesViewsAndEnrolledBarChart
                    width='15vw'
                    height='15vw'
                    label='# of course views per field'
                    dataKey='views'
                    data={FieldsViewsAndEnrolledBarChartData}
                  ></CoursesViewsAndEnrolledBarChart> */}

                  <RadarChar
                    width='400px'
                    height='300px'
                    label='Average score per question'
                    dataKey='views'
                    data={CourseQuestionData}
                  ></RadarChar>
                </Row>
                <Row>
                  <Col>
                    <CoursesViewsAndEnrolledBarChart
                      width='600px'
                      height='700px'
                      label='# of views per course'
                      dataKey='views'
                      dy={0}
                      dx={-5}
                      Xheight={400}
                      padding={0}
                      angle={270}
                      data={CoursesViewsAndEnrolledBarChartData}
                    ></CoursesViewsAndEnrolledBarChart>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Col>
                  <CoursesViewsAndEnrolledBarChartVertical
                    width='600px'
                    // height='100'
                    label='# of enrollments per course'
                    dataKey='enrolled'
                    dy={40}
                    dx={20}
                    Xheight={110}
                    padding={20}
                    angle={60}
                    data={CoursesViewsAndEnrolledBarChartData}
                  ></CoursesViewsAndEnrolledBarChartVertical>
                </Col>
              </Col>
            </Row>
            <Row>
              <CoursesViewsAndEnrolledBarChart
                width='15vw'
                height='15vw'
                label='# of course enrollments per field'
                dataKey='enrolled'
                data={FieldsViewsAndEnrolledBarChartData}
              ></CoursesViewsAndEnrolledBarChart>
              {courses && (
                <PieChartComponent
                  width='25vw'
                  height='20vw'
                  label='Assessment Completion'
                  data={CourseSectionCompletions}
                  courses={courses}
                  setCourseSectionCompletions={setCourseSectionCompletions}
                ></PieChartComponent>
              )}
            </Row>
          </Container>
        )}
        {pathArray[2] === 'users' && (
          <Container style={{ marginLeft: '1vw', marginTop: '1vw' }}>
            <div className='analyticsUserHeader'>
              <div
                className={
                  pathArray[3] === 'courses' ? 'analyticsSideBarSelected' : null
                }
                onClick={() => changeSelected('users/courses')}
              >
                <span>Course Details</span>
              </div>
              <div
                className={
                  pathArray[3] === 'accounts'
                    ? 'analyticsSideBarSelected'
                    : null
                }
                onClick={() => changeSelected('users/accounts')}
              >
                <span>Account Details</span>
              </div>
            </div>

            {pathArray[3] === 'courses' && (
              <div>
                <div className='printButtons'>
                  <PrintDetailed />
                  <Print />
                  <PrintCollapsed />
                </div>

                <Container
                  ref={componentRef}
                  className='analyticUserPageContainer'
                >
                  <DisplayUserCourseCompletions
                    inputDefault={accordionExpansion}
                  />
                </Container>
              </div>
            )}

            {pathArray[3] === 'accounts' && (
              <div>
                <div className='printButtons'>
                  <ExportExcel dataSet={userAccountsData}></ExportExcel>
                  <PrintAccountDetails />
                </div>

                {/* Filters */}
                <div style={{ display: 'flex' }}>
                  <TextField
                    id='search'
                    label='Search'
                    variant='standard'
                    style={{
                      marginBottom: '0.5vw',
                      width: '65%',
                    }}
                    value={filters.search}
                    onChange={handleFilterChange}
                  />
                  <div className='analyticsDropdown'>
                    <DropdownButton
                      align={{ lg: 'start' }}
                      id='field'
                      title={filters.field == '' ? 'Field' : filters.field}
                    >
                      <Dropdown.Item
                        id='field'
                        key='All'
                        value='All'
                        onClick={handleFilterChange}
                      >
                        All
                      </Dropdown.Item>
                      <Dropdown.Item
                        id='field'
                        key='Wounds'
                        value='Wounds'
                        onClick={handleFilterChange}
                      >
                        Wounds
                      </Dropdown.Item>
                      <Dropdown.Item
                        id='field'
                        key='Joints'
                        value='Joints'
                        onClick={handleFilterChange}
                      >
                        Joints
                      </Dropdown.Item>
                      <Dropdown.Item
                        id='field'
                        key='Eyes'
                        value='Eyes'
                        onClick={handleFilterChange}
                      >
                        Eyes
                      </Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton
                      align={{ lg: 'start' }}
                      id='country'
                      title={
                        filters.country == '' ? 'Country' : filters.country
                      }
                    >
                      <Dropdown.Item
                        id='country'
                        key='Egypt'
                        onClick={handleFilterChange}
                      >
                        Egypt
                      </Dropdown.Item>
                      <Dropdown.Item
                        id='country'
                        key='Morocco'
                        onClick={handleFilterChange}
                      >
                        Morocco
                      </Dropdown.Item>
                      <Dropdown.Item
                        id='country'
                        key='Global'
                        onClick={handleFilterChange}
                      >
                        Global
                      </Dropdown.Item>
                      <Dropdown.Item
                        id='country'
                        key='Italy'
                        onClick={handleFilterChange}
                      >
                        Italy
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>

                  <div className='analyticsRemoveFilters'>
                    <TooltipMUI title='Remove Filters' placement='top'>
                      <IconButton
                        color='primary'
                        aria-label='upload picture'
                        onClick={resetFilters}
                      >
                        <SearchOffIcon />
                      </IconButton>
                    </TooltipMUI>
                  </div>
                </div>

                <Container
                  ref={accountDetailsRef}
                  className='analyticUserPageContainer'
                >
                  <DisplayUserAccounts />
                </Container>
              </div>
            )}
          </Container>
        )}
      </div>
    </div>
  )
}
