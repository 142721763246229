import { combineReducers } from 'redux'
import {
  tokenReducer,
  idReducer,
  nameReducer,
  typeReducer,
  countryReducer,
  fieldReducer,
  isLineManagerReducer,
} from './SigninReducer'

export const root = combineReducers({
  token: tokenReducer,
  id: idReducer,
  name: nameReducer,
  type: typeReducer,
  country: countryReducer,
  field: fieldReducer,
  isLineManager: isLineManagerReducer,
})
