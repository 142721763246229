import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ReactComponent as Logo } from '../images/logo.svg'
import { ReactComponent as User } from '../images/user.svg'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { logout } from '../globalState/actions/AuthActions'
import { useCookies } from 'react-cookie'
import { ReactComponent as FlagOfUK } from '../images/Flag_of_the_United_Kingdom.svg'
import { ReactComponent as FlagOfItaly } from '../images/Flag_of_Italy.svg'
import { T } from '../Languages/LanguageHandler'
export default function Header() {
  const dict = T()
  const dispatch = useDispatch()
  const history = useHistory()
  const signout = async (e) => {
    await dispatch(logout(history))
  }
  const handleChangeLanguage = (language) => {
    console.log(language)
    setCookie('Language', language, { path: '/' })
  }
  const [cookies, setCookie] = useCookies(['Privacy Policy', 'Language'])
  let languageSelected = cookies['Language']
  const navigate = (path) => {
    window.location.href = path
    history.push(path)
  }
  const type = useSelector((state) => state.type)

  return (
    <div>
      <Row className='headerTableResponsive'>
        <Col>
          <Logo
            xs={3}
            sm={3}
            md={3}
            xxl={2}
            onClick={() => navigate('/home')}
            style={{
              marginLeft: '18%',
              display: 'flex',
              marginTop: '5%',
              marginBottom: '5%',
            }}
            className='headerLogoResponsiveWidth headerTableResponsiveColumn'
          ></Logo>
        </Col>
        {type === 'admin' ? (
          <Col
            xs={9}
            sm={{
              span: '5',
              offset: '4',
            }}
            md={{
              span: '4',
              offset: '5',
            }}
            lg={{
              span: '3',
              offset: '7',
            }}
            xxl={{
              span: '2',
              offset: '8',
            }}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <div
              style={{
                width: '28%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => navigate('/cp')}
              className='headerTableResponsiveColumn'
            >
              {' '}
              <h6 style={{ fontsize: '0.75rem' }}>Control Panel</h6>
            </div>
            <div
              style={{
                width: '28%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => navigate('/analytics/dashboard')}
              className='headerTableResponsiveColumn'
            >
              <h6>Analytics</h6>
            </div>
            <div
              style={{
                width: '28%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={signout}
              className='headerTableResponsiveColumn'
            >
              <h6>{dict['Signout']}</h6>
            </div>
            <div
              style={{
                width: '16%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => navigate('/myprofile')}
            >
              <User
                className='headerUserResponsiveWidth headerTableResponsiveColumn'
                style={{ color: '#475677' }}
              ></User>
            </div>
          </Col>
        ) : (
          <Col
            xs={9}
            sm={{
              span: '4',
              offset: '5',
            }}
            md={{
              span: '4',
              offset: '5',
            }}
            lg={{
              span: '3',
              offset: '7',
            }}
            xxl={{
              span: '2',
              offset: '8',
            }}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <div
              style={{
                marginRight: '5%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => navigate('/home')}
              className='headerTableResponsiveColumn'
            >
              {' '}
              {dict['Home']}
            </div>
            <div
              style={{
                marginRight: '5%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => navigate('/courses')}
              className='headerTableResponsiveColumn'
            >
              {dict['Courses']}
            </div>
            <div
              style={{
                marginRight: '5%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={signout}
              className='headerTableResponsiveColumn'
            >
              {dict['Signout']}
            </div>
            <div
              style={{
                marginRight: '5%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {console.log(languageSelected)}
              {languageSelected == 'Italy' && (
                <FlagOfUK
                  style={{
                    marginLeft: '1%',
                    marginRight: '1%',
                    cursor: 'pointer',
                    filter: 'brightness(0.8)',
                  }}
                  onClick={() => handleChangeLanguage('Global')}
                  className='headerUserResponsiveWidth'
                ></FlagOfUK>
              )}
              {languageSelected == 'Global' && (
                <FlagOfItaly
                  style={{
                    marginLeft: '1%',
                    marginRight: '1%',
                    cursor: 'pointer',
                    filter: 'brightness(0.8)',
                  }}
                  onClick={() => handleChangeLanguage('Italy')}
                  className='headerUserResponsiveWidth'
                ></FlagOfItaly>
              )}
            </div>
            <div
              style={{
                // width: '36%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => navigate('/myprofile')}
            >
              <User
                style={{
                  // width: '40px',
                  // height: '24px',
                  cursor: 'pointer',
                  filter: 'brightness(0.8)',
                  color: '#475677',
                  marginRight: '5px',
                }}
                className='headerUserResponsiveWidth'
              ></User>
            </div>
          </Col>
        )}
        {/* <User style={{ color: '#475677' }}></User> */}
      </Row>
    </div>
  )
}
