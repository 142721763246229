import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Row, Col } from 'react-bootstrap'
import axios from 'axios'
import { backendLink } from '../keys_dev'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import { T } from '../Languages/LanguageHandler'
import { ReactComponent as FlagOfUK } from '../images/Flag_of_the_United_Kingdom.svg'
import { ReactComponent as FlagOfItaly } from '../images/Flag_of_Italy.svg'

// import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useCookies } from 'react-cookie'

export default function Login() {
  const dict = T()
  const [cookies, setCookie] = useCookies(['Privacy Policy', 'Language'])
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [country, setCountry] = useState(dict['ChooseCountry'])
  const [field, setField] = useState(dict['ChooseField'])
  const [lineManagers, setLineManagers] = useState([])
  const [lineManager, setLineManager] = useState({
    name: dict['ChooseLineManager'],
    id: null,
  })

  const handleChangeLanguage = (language) => {
    setCookie('Language', language, { path: '/' })
  }
  useEffect(() => {
    axios({
      url: `${backendLink}/api/v1/user/getAdmins`,
      method: 'GET',
    })
      .then((res) => {
        setLineManagers(res.data.users)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  useEffect(() => {
    setCountry(dict['ChooseCountry'])
    setField(dict['ChooseField'])
    setLineManager({
      name: dict['ChooseLineManager'],
      id: null,
    })
  }, [cookies['Language']])

  // const dispatch = useDispatch()
  const history = useHistory()

  const emailChange = (event) => {
    setEmail(event.target.value)
  }
  const passwordChange = (event) => {
    setPassword(event.target.value)
  }

  const firstNameChange = (event) => {
    setFirstName(event.target.value)
  }
  const lastNameChange = (event) => {
    setLastName(event.target.value)
  }

  const countryChange = (event) => {
    setCountry(event.target.id)
  }
  const fieldChange = (event) => {
    setField(event.target.id)
  }
  const lineManagerChange = (event) => {
    setLineManager({
      name: event.target.innerText,
      id: event.target.id,
    })
  }

  const goLogin = () => {
    history.push('/')
  }
  const signUp = async (e) => {
    let data = {
      email: email,
      password: password,
      name: firstName + ' ' + lastName,
      country: country,
      field: field,
    }
    if (lineManager.id) {
      data.lineManager = lineManager
    }
    console.log(data)
    await axios({
      url: `${backendLink}/api/v1/user`,
      method: 'POST',
      data: data,
    })
      .then((res) => {
        if (res.status === 201) {
          console.log(res)
          history.push('/')
        } else {
          console.log('FAIL')
        }
        return res.data
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      signUp()
    }
  }

  return (
    <div style={{ marginBottom: '10px' }}>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td>
              <h1 className='display-3 LoginSignupHeader m-0'>
                {dict['Signup']}
              </h1>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '50px',
              }}
            >
              <FlagOfUK
                className={
                  cookies['Language'] === 'Global'
                    ? 'languageFlagsSelected'
                    : ''
                }
                style={{
                  width: '80px',
                  height: '64px',
                  marginLeft: '1%',
                  marginRight: '1%',
                  cursor: 'pointer',
                  filter: 'brightness(0.8)',
                }}
                onClick={() => handleChangeLanguage('Global')}
              ></FlagOfUK>
              <FlagOfItaly
                className={
                  cookies['Language'] === 'Italy' ? 'languageFlagsSelected' : ''
                }
                style={{
                  width: '80px',
                  height: '64px',
                  marginLeft: '1%',
                  marginRight: '1%',
                  cursor: 'pointer',
                  filter: 'brightness(0.8)',
                }}
                onClick={() => handleChangeLanguage('Italy')}
              ></FlagOfItaly>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '2%',
              }}
            >
              <Form
                className='responsiveLoginFormWidth'
                onKeyPress={handleKeyPress}
              >
                <Row xs={1} md={1} lg={2}>
                  <Form.Group as={Col} controlId='firstName'>
                    <Form.Control
                      onChange={firstNameChange}
                      placeholder={dict['FirstName']}
                      className='LoginSignupFormControlResponsive responsiveSignUpNameInput'
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId='lastName'>
                    <Form.Control
                      onChange={lastNameChange}
                      placeholder={dict['LastName']}
                      className='LoginSignupFormControlResponsive responsiveSignUpNameInput responsiveSignUpNameInputLastName'
                    />
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group controlId='email' style={{ marginTop: '2vw' }}>
                    <Form.Control
                      onChange={emailChange}
                      type='email'
                      placeholder={dict['Email']}
                      className='LoginSignupFormControlResponsive'
                    />
                  </Form.Group>
                  <Form.Group controlId='password' style={{ marginTop: '2vw' }}>
                    <Form.Control
                      onChange={passwordChange}
                      type='password'
                      placeholder={dict['Password']}
                      className='LoginSignupFormControlResponsive'
                    />
                  </Form.Group>
                </Row>
                <Row xs={1} md={1} lg={3}>
                  <DropdownButton
                    className='countryButton'
                    id='dropdown-basic-button'
                    title={country}
                  >
                    <Dropdown.Item id='Egypt' onClick={countryChange}>
                      Egypt
                    </Dropdown.Item>
                    <Dropdown.Item id='Morocco' onClick={countryChange}>
                      Morocco
                    </Dropdown.Item>
                    <Dropdown.Item id='Global' onClick={countryChange}>
                      Global
                    </Dropdown.Item>
                    <Dropdown.Item id='Italy' onClick={countryChange}>
                      Italy
                    </Dropdown.Item>
                  </DropdownButton>
                  <DropdownButton
                    className='countryButton'
                    id='dropdown-basic-button'
                    title={field}
                  >
                    <Dropdown.Item id='All' onClick={fieldChange}>
                      {dict['All']}
                    </Dropdown.Item>
                    <Dropdown.Item id='Joints' onClick={fieldChange}>
                      {dict['Joints']}
                    </Dropdown.Item>
                    <Dropdown.Item id='Skin' onClick={fieldChange}>
                      {dict['Skin']}
                    </Dropdown.Item>
                    <Dropdown.Item id='Eyes' onClick={fieldChange}>
                      {dict['Eyes']}
                    </Dropdown.Item>
                    <Dropdown.Item id='Aesthetics' onClick={fieldChange}>
                      {dict['Aesthetics']}
                    </Dropdown.Item>
                    <Dropdown.Item id='Regenerative' onClick={fieldChange}>
                      {dict['Regenerative']}
                    </Dropdown.Item>
                    <Dropdown.Item id='Neurosciences' onClick={fieldChange}>
                      {dict['Neurosciences']}
                    </Dropdown.Item>
                    <Dropdown.Item id='Ginecology' onClick={fieldChange}>
                      {dict['Ginecology']}
                    </Dropdown.Item>
                    <Dropdown.Item id='Cardiometabolis' onClick={fieldChange}>
                      {dict['Cardiometabolis']}
                    </Dropdown.Item>
                    <Dropdown.Item
                      id='Respiratory & Anti-Infective'
                      onClick={fieldChange}
                    >
                      {dict['Respiratory & Anti-Infective']}
                    </Dropdown.Item>
                  </DropdownButton>
                  <DropdownButton
                    className='countryButton'
                    id='dropdown-basic-button'
                    title={lineManager.name}
                  >
                    {lineManagers.map((manager) => (
                      <Dropdown.Item
                        id={manager._id}
                        key={manager._id}
                        onClick={lineManagerChange}
                      >
                        {manager.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Row>
              </Form>
            </td>
          </tr>
          <tr>
            <td style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={signUp} className='LoginSignupButton'>
                <h4>
                  {' '}
                  {dict['Signup']} {'>'}
                </h4>
              </Button>
            </td>
          </tr>
          <tr>
            <td
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '0.4%',
              }}
            >
              <h6
                style={{
                  fontWeight: '400',
                }}
              >
                {dict['AlreadyHaveAnAccount']}
                <span onClick={goLogin} className='hyperlinkText'>
                  {'  '}

                  {dict['Login']}
                </span>{' '}
              </h6>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
