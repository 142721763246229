import React from 'react'
import Form from 'react-bootstrap/Form'
import { Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { T } from '../Languages/LanguageHandler'

export default function CourseFilter(props) {
  const dict = T()
  const country = useSelector((state) => state.country)
  const handleNameChange = (event) => {
    props.setName(event.target.value)
  }
  const handleCategoryChange = (input) => {
    if (input === 'All') {
      props.setCategory('')
    } else props.setCategory(input)
  }

  return (
    <div className='courseFilterResponsive'>
      <Form style={{ width: '60%' }}>
        <Form.Group controlId='courseName'>
          <Form.Control
            onChange={handleNameChange}
            placeholder={dict['Search for courses']}
            className='courseFilter'
          />
        </Form.Group>
      </Form>
      <Dropdown className='courseCategoriesButton'>
        <Dropdown.Toggle
          style={{ maxWidth: '100%' }}
          variant='success'
          id='dropdown-autoclose-outside'
        >
          <span className='CategoriesButton'>{dict['Categories']}</span>{' '}
          <span className='CategoriesButtonArrow'>▼</span>
        </Dropdown.Toggle>

        {country === 'Global' && (
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleCategoryChange('All')}>
              <h6>{dict['All']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange('Eyes')}>
              <h6>{dict['Eyes']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange('Skin')}>
              <h6>{dict['Skin']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange('Joints')}>
              <h6>{dict['Joints']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange('Aesthetics')}>
              <h6>{dict['Aesthetics']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange('Regenerative')}>
              <h6>{dict['Regenerative']}</h6>
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
        {country === 'Italy' && (
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleCategoryChange('All')}>
              <h6>{dict['All']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange('Eyes')}>
              <h6>{dict['Eyes']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange('Skin')}>
              <h6>{dict['Skin']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange('Joints')}>
              <h6>{dict['Joints']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange('Aesthetics')}>
              <h6>{dict['Aesthetics']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange('Regenerative')}>
              <h6>{dict['Regenerative']}</h6>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleCategoryChange('Neurosciences')}
            >
              <h6>{dict['Neurosciences']}</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange('Ginecology')}>
              <h6>{dict['Ginecology']}</h6>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleCategoryChange('Cardiometabolis')}
            >
              <h6>{dict['Cardiometabolis']}</h6>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleCategoryChange('Respiratory & Anti-Infective')
              }
            >
              <h6>{dict['Respiratory & Anti-Infective']}</h6>
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  )
}
