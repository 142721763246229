module.exports = {
  countries: ['Egypt', 'Morocco', 'Global', 'Italy'],
  fields: [
    'Joints',
    'Skin',
    'Aesthetics',
    'Regenerative',
    'Aesthetics',
    'Neurosciences',
    'Ginecology',
    'Cardiometabolis',
    'Respiratory & Anti-Infective',
  ],
}
