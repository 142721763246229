import axios from 'axios'
import { backendLink } from '../../keys_dev'
import jwt from 'jsonwebtoken'
export const loginAPI = (email, password, history) => {
  return async (dispatch, getState) => {
    return await axios({
      url: `${backendLink}/api/v1/auth`,
      method: 'POST',
      data: {
        email,
        password,
      },
    }).then((res) => {
      if (res.status === 200) {
        const token = res.data.token
        const id = res.data.id
        const name = res.data.name
        const type = res.data.type
        const country = res.data.country
        const field = res.data.field
        const isLineManager = res.data.isLineManager
        console.log(res.data)
        dispatch(setToken(token))
        dispatch(setID(id))
        dispatch(setName(name))
        dispatch(setType(type))
        dispatch(setCountry(country))
        dispatch(setField(field))
        dispatch(setIsLineManager(isLineManager))
        history.push('/')
      } else {
        dispatch(unsetToken())
        dispatch(unsetID())
        dispatch(unsetName())
        dispatch(unsetType())
        dispatch(unsetCountry())
        dispatch(unsetField())
        dispatch(unsetIsLineManager())
      }
      return res.data
    })
  }
}
export const checkTokenExpired = (history) => {
  return function (dispatch, getState) {
    const token = getState().token
    if (!token) {
      history.push('/')
      return
    }
    if (jwt.decode(token).exp < Date.now() / 1000) {
      dispatch(logout(history))
      return true
    } else {
      return false
    }
  }
}

const setToken = (payload) => {
  return {
    type: 'SET_TOKEN',
    payload,
  }
}

const unsetToken = () => {
  return {
    type: 'UNSET_TOKEN',
    payload: '',
  }
}

const setType = (payload) => {
  return {
    type: 'SET_TYPE',
    payload,
  }
}

const unsetType = () => {
  return {
    type: 'UNSET_TYPE',
    payload: '',
  }
}

const setName = (payload) => {
  return {
    type: 'SET_NAME',
    payload,
  }
}

const unsetName = () => {
  return {
    type: 'UNSET_NAME',
    payload: '',
  }
}

const setID = (payload) => {
  return {
    type: 'SET_ID',
    payload,
  }
}

const unsetID = () => {
  return {
    type: 'UNSET_ID',
    payload: '',
  }
}
const setCountry = (payload) => {
  return {
    type: 'SET_COUNTRY',
    payload,
  }
}

const unsetCountry = () => {
  return {
    type: 'UNSET_COUNTRY',
    payload: '',
  }
}
const setField = (payload) => {
  return {
    type: 'SET_FIELD',
    payload,
  }
}

const unsetField = () => {
  return {
    type: 'UNSET_FIELD',
    payload: '',
  }
}
const setIsLineManager = (payload) => {
  return {
    type: 'SET_ISLINEMANAGER',
    payload,
  }
}

const unsetIsLineManager = () => {
  return {
    type: 'UNSET_ISLINEMANAGER',
    payload: '',
  }
}

export const logout = (history) => {
  return (dispatch, getState) => {
    dispatch(unsetToken())
    dispatch(unsetID())
    dispatch(unsetName())
    dispatch(unsetType())
    dispatch(unsetCountry())
    dispatch(unsetField())
    dispatch(unsetIsLineManager())

    history.push('/')
  }
}
