import React from 'react'
import Header from '../Components/Header'
import { ReactComponent as EyeCare } from '../images/eyeCare.svg'
import { ReactComponent as WoundCare } from '../images/woundCare.svg'
import { ReactComponent as JoinCare } from '../images/jointCare.svg'
import { useHistory } from 'react-router'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { T } from '../Languages/LanguageHandler'
export default function Home() {
  const history = useHistory()
  const country = useSelector((state) => state.country)
  const dict = T()
  const navigate = (path) => {
    history.push(path)
  }

  return (
    <div>
      <Header></Header>
      <div className='homeBanner paddingInlineResponsive '>
        <h1 className='homeP1 display-4'>{dict['Learn Something New']}</h1>
        <h1 className='homeP2 display-5'>Lorem ipsum.</h1>
        <h6 className='homeP3'>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
        </h6>
      </div>
      {country === 'Global' && (
        <Row
          md={3}
          xs={1}
          style={{
            marginTop: '5%',
            width: '80%',
            marginLeft: '10%',
          }}
        >
          <Col
            style={{
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='homeCategoryBoundry'
              onClick={() => navigate('/courses/joints')}
            >
              <JoinCare
                style={{
                  width: '75%',
                  height: '75% ',
                  marginTop: '0vw',
                  justifySelf: 'center',
                }}
              ></JoinCare>
              <h4 className='homeCategoryfont'>{dict['Joint Care']}</h4>
            </div>
          </Col>
          <Col
            style={{
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='homeCategoryBoundry'
              onClick={() => navigate('/courses/skin')}
            >
              <WoundCare
                style={{
                  width: '75%',
                  height: '75% ',
                  marginTop: '0vw',
                  justifySelf: 'center',
                }}
              ></WoundCare>
              <h4 className='homeCategoryfont'>{dict['Skin Care']}</h4>
            </div>
          </Col>
          <Col
            style={{
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='homeCategoryBoundry'
              onClick={() => navigate('/courses/aesthetics')}
            >
              <WoundCare
                style={{
                  width: '75%',
                  height: '75% ',
                  marginTop: '0vw',
                  justifySelf: 'center',
                }}
              ></WoundCare>
              <h4 className='homeCategoryfont'>{dict['Aesthetics Care']}</h4>
            </div>
          </Col>
          <Col
            style={{
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='homeCategoryBoundry'
              onClick={() => navigate('/courses/regenerative')}
            >
              <WoundCare
                style={{
                  width: '75%',
                  height: '75% ',
                  marginTop: '0vw',
                  justifySelf: 'center',
                }}
              ></WoundCare>
              <h4 className='homeCategoryfont'>{dict['Regenerative Care']}</h4>
            </div>
          </Col>
          <Col
            style={{
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='homeCategoryBoundry'
              onClick={() => navigate('/courses/eyes')}
            >
              <EyeCare
                style={{
                  width: '75%',
                  height: '75% ',
                  marginTop: '0vw',
                  justifySelf: 'center',
                }}
              ></EyeCare>
              <h4 className='homeCategoryfont'>{dict['Eye Care']}</h4>
            </div>
          </Col>
        </Row>
      )}
      {country === 'Italy' && (
        <Row
          md={3}
          xs={1}
          style={{
            marginTop: '5%',
            width: '80%',
            marginLeft: '10%',
          }}
        >
          <Col
            style={{
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='homeCategoryBoundry'
              onClick={() => navigate('/courses/joints')}
            >
              <JoinCare
                style={{
                  width: '75%',
                  height: '75% ',
                  marginTop: '0vw',
                  justifySelf: 'center',
                }}
              ></JoinCare>
              <h4 className='homeCategoryfont'>{dict['Joint Care']}</h4>
            </div>
          </Col>
          <Col
            style={{
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='homeCategoryBoundry'
              onClick={() => navigate('/courses/skin')}
            >
              <WoundCare
                style={{
                  width: '75%',
                  height: '75% ',
                  marginTop: '0vw',
                  justifySelf: 'center',
                }}
              ></WoundCare>
              <h4 className='homeCategoryfont'>{dict['Skin Care']}</h4>
            </div>
          </Col>
          <Col
            style={{
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='homeCategoryBoundry'
              onClick={() => navigate('/courses/aesthetics')}
            >
              <WoundCare
                style={{
                  width: '75%',
                  height: '75% ',
                  marginTop: '0vw',
                  justifySelf: 'center',
                }}
              ></WoundCare>
              <h4 className='homeCategoryfont'>{dict['Aesthetics Care']}</h4>
            </div>
          </Col>
          <Col
            style={{
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='homeCategoryBoundry'
              onClick={() => navigate('/courses/regenerative')}
            >
              <WoundCare
                style={{
                  width: '75%',
                  height: '75% ',
                  marginTop: '0vw',
                  justifySelf: 'center',
                }}
              ></WoundCare>
              <h4 className='homeCategoryfont'>{dict['Regenerative Care']}</h4>
            </div>
          </Col>
          <Col
            style={{
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='homeCategoryBoundry'
              onClick={() => navigate('/courses/eyes')}
            >
              <EyeCare
                style={{
                  width: '75%',
                  height: '75% ',
                  marginTop: '0vw',
                  justifySelf: 'center',
                }}
              ></EyeCare>
              <h4 className='homeCategoryfont'>{dict['Eye Care']}</h4>
            </div>
          </Col>
          <Col
            style={{
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='homeCategoryBoundry'
              onClick={() => navigate('/courses/neurosciences')}
            >
              <WoundCare
                style={{
                  width: '75%',
                  height: '75% ',
                  marginTop: '0vw',
                  justifySelf: 'center',
                }}
              ></WoundCare>
              <h4 className='homeCategoryfont'>{dict['Neurosciences']}</h4>
            </div>
          </Col>
          <Col
            style={{
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='homeCategoryBoundry'
              onClick={() => navigate('/courses/ginecology')}
            >
              <WoundCare
                style={{
                  width: '75%',
                  height: '75% ',
                  marginTop: '0vw',
                  justifySelf: 'center',
                }}
              ></WoundCare>
              <h4 className='homeCategoryfont'>{dict['Ginecology']}</h4>
            </div>
          </Col>
          <Col
            style={{
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='homeCategoryBoundry'
              onClick={() => navigate('/courses/cardiometabolis')}
            >
              <WoundCare
                style={{
                  width: '75%',
                  height: '75% ',
                  marginTop: '0vw',
                  justifySelf: 'center',
                }}
              ></WoundCare>
              <h4 className='homeCategoryfont'>{dict['Cardiometabolis']}</h4>
            </div>
          </Col>
          <Col
            style={{
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='homeCategoryBoundry'
              onClick={() => navigate('/courses/respiratory')}
            >
              <WoundCare
                style={{
                  width: '75%',
                  height: '75% ',
                  marginTop: '0vw',
                  justifySelf: 'center',
                }}
              ></WoundCare>
              <h4 className='homeCategoryfont'>
                {dict['Respiratory & Anti-Infective']}
              </h4>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
