import React, { useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
import Button from '@restart/ui/esm/Button'
import { T } from '../Languages/LanguageHandler'
export default function PrivacyPolicyPopup(props) {
  const dict = T()
  const [disableButtons, setDisableButtons] = useState(false)
  const handleClose = async (accepted) => {
    setDisableButtons(true)
    if (accepted) {
      props.setCookie('Privacy Policy', true, { path: '/' })
      props.logIn()
    }
    setDisableButtons(false)
    props.setShowPopup(false)
  }

  return (
    <Modal
      show={props.showPopup}
      size='lg'
      centered
      dialogClassName='Popup-modalRadius'
    >
      <Modal.Body>
        <h1
          className='courseAboutTitle display-5'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {dict['PrivacyPolicy']}
        </h1>
        <h3
          className='courseAboutTitle privacyPolicyPopupText'
          style={{ display: 'flex', color: 'black', justifyContent: 'center' }}
        >
          {dict['PrivacyPolicyPopUp']}
        </h3>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => handleClose(true)}
            className='CourseSubmitAnswersButton'
            disabled={disableButtons}
          >
            {dict['Accept']}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
