import React from 'react'
import ReactExport from 'react-export-excel'
import moment from 'moment'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export default function ExportExcel(props) {
  return (
    <ExcelFile
      element={
        <button style={{ width: '10.5vw' }}>Download Excel Sheet</button>
      }
      filename={'User Credentials at ' + moment().format('LLLL')}
    >
      <ExcelSheet data={props.dataSet} name='Users'>
        <ExcelColumn label='Name' value='name' />
        <ExcelColumn label='E-mail' value='email' />
        <ExcelColumn label='Country' value='country' />
        <ExcelColumn label='Field' value='field' />
      </ExcelSheet>
    </ExcelFile>
  )
}
