export default {
  Home: 'Home',
  Courses: 'Courses',
  Signout: 'Signout',
  'Learn Something New': 'Learn Something New',
  'Joint Care': 'Joint Care',
  'Skin Care': 'Skin Care',
  'Aesthetics Care': 'Aesthetics Care',
  'Regenerative Care': 'Regenerative Care',
  'Eye Care': 'Eye Care',
  Neurosciences: 'Neurosciences',
  Ginecology: 'Ginecology',
  Cardiometabolis: 'Cardiometabolis',
  'Respiratory & Anti-Infective': 'Respiratory & Anti-Infective',
  'Search for courses': 'Search for courses',
  Categories: 'Categories',
  All: 'All',
  Views: 'Views',
  Joints: 'Joints',
  Skin: 'Skin',
  Eyes: 'Eyes',
  Aesthetics: 'Aesthetics',
  Regenerative: 'Regenerative',
  Browse: 'Browse',
  'Enroll now': 'Enroll now',
  'already enrolled': 'already enrolled',
  About: 'About',
  Score: 'Score',
  Syllabus: 'Syllabus',
  'About this Course': 'About this Course',
  Instructor: 'Instructor',
  Syllabus: 'Syllabus',
  'This course has no chapters yet.': 'This course has no chapters yet.',
  Progress: 'Progress',
  Enrolled: 'Enrolled',
  'Pre chapter assessment': 'Pre chapter assessment',
  'Answer the following questions before proceeding to the chapter content.':
    'Answer the following questions before proceeding to the chapter content.',
  Question: 'Question',
  Submit: 'Submit',
  'Chapter assessment': 'Chapter assessment',
  'Answer the following questions to proceed to the next chapter.':
    'Answer the following questions to proceed to the next chapter.',
  'Your Score:': 'Your Score:',
  'Correct Answers:': 'Correct Answers:',
  'Next Section': 'Next Section',
  'My Courses': 'My Courses',
  Scores: 'Scores',
  'Course Name': 'Course Name',
  'Percentage Done': 'Percentage Done',
  'Date of Enrollment': 'Date of Enrollment',
  Completion: 'Completion',
  'Not Completed': 'Not Completed',
  Completed: 'Completed',
  'Enrolled at:': 'Enrolled at:',
  'Pre Assessment Score': 'Pre Assessment Score',
  'Post Assessment Score': 'Post Assessment Score',
  Certificates: 'Certificates',
  'Account details': 'Account details',
  'My account': 'My account',
  Osteoporosis: 'Osteoporosis',
  Pain: 'Pain',
  Corticosteroids: 'Corticosteroids',
  True: 'True',
  False: 'False',
  PrivacyPolicyLogin:
    'I hereby accept that acquisition, disclosure and download of the available material for any reason is not authorized by Fidia, because it is training material for internal use only.',
  Email: 'Email',
  Password: 'Password',
  Login: 'Login',
  Signup: 'Sign up',
  DontHaveAnAccount: "Don't have an account?",
  AlreadyHaveAnAccount: 'Already have an account?',
  FirstName: 'First Name',
  LastName: 'Last Name',
  ChooseCountry: 'Choose Country',
  ChooseField: 'Choose Field',
  ChooseLineManager: 'Choose Line Manager',
  PasswordIsRequired: 'Password is Required',
  EmailIsRequired: 'Email is Required',
  PrivacyPolicyShouldBeAccepted: 'Privacy policy should be accepted',
  PrivacyPolicy: 'Privacy Policy',
  PrivacyPolicyPopUp:
    'I hereby accept that acquisition, disclosure and download of the available material for any reason is not authorized by Fidia, because it is training material for internal use only.',
  Accept: 'Accept',
  InvalidLoginCredentials: 'Invalid login credentials',
}
