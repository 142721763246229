export default {
  Home: 'Pagina iniziale',
  Courses: 'Corsi',
  Signout: 'Disconnessione',
  'Learn Something New': 'Impara qualcosa di nuovo',
  'Joint Care': 'Cura delle articolazioni',
  'Skin Care': 'Cura della pelle',
  'Aesthetics Care': 'Cura estetica',
  'Regenerative Care': 'Cure Rigenerative',
  'Eye Care': 'Cura degli occhi',
  Neurosciences: 'Neuroscienze',
  Ginecology: 'Ginecologia',
  Cardiometabolis: 'Cardiometabolico',
  'Respiratory & Anti-Infective': 'Respiratorio e anti-infettivo',
  'Search for courses': 'Cerca i corsi',
  Categories: 'Categorie',
  All: 'Tutto',
  Views: 'Visualizzazioni',
  Joints: 'Giunti',
  Skin: 'Pelle',
  Eyes: 'Occhi',
  Aesthetics: 'Estetica',
  Regenerative: 'Rigenerativo',
  Browse: 'Navigare',
  'Enroll now': 'Iscriviti ora',
  'already enrolled': 'già iscritto',
  About: 'Di',
  Score: 'Punto',
  Syllabus: 'Programma',
  'About this Course': 'A proposito di questo corso',
  Instructor: 'Istruttore',
  Syllabus: 'Programma',
  'This course has no chapters yet.': 'Questo corso non ha ancora capitoli.',
  Progress: 'Progresso',
  Enrolled: 'Iscritto',
  'Pre chapter assessment': 'Valutazione pre-capitolo',
  'Answer the following questions before proceeding to the chapter content.':
    'Rispondi alle seguenti domande prima di passare al contenuto del capitolo.',
  Question: 'Domanda',
  Submit: 'Invia',
  'Chapter assessment': 'Valutazione del capitolo',
  'Answer the following questions to proceed to the next chapter.':
    'Rispondi alle seguenti domande per passare al capitolo successivo.',
  'Your Score:': 'Il tuo punteggio:',
  'Correct Answers:': 'Risposte corrette:',
  'Next Section': 'Sezione successiva',
  'My Courses': 'I miei corsi',
  Scores: 'Punteggi',
  'Course Name': 'Nome del corso',
  'Percentage Done': 'Percentuale completata',
  'Date of Enrollment': 'Data di iscrizione',
  Completion: 'Completamento',
  'Not Completed': 'Non completato',
  Completed: 'Completato',
  'Enrolled at:': 'Iscritto a:',
  'Pre Assessment Score': 'Punteggio pre-valutazione',
  'Post Assessment Score': 'Punteggio post valutazione',
  Certificates: 'Certificati',
  'Account details': 'Dettagli account',
  'My account': 'Il mio account',
  Osteoporosis: 'Osteoporosi',
  Pain: 'Dolore',
  Corticosteroids: 'Corticosteroidi',
  True: 'Vero',
  False: 'Falso',
  PrivacyPolicyLogin:
    "Accetto che l'acquisizione, la divulgazione e il download del materiale disponibile per qualsiasi motivo non sia autorizzato da Fidia, poiché si tratta di materiale formativo ad uso esclusivamente interno.",
  Email: 'Email',
  Password: "Parola d'ordine",
  Login: 'Login',
  Signup: 'Iscrizione',
  DontHaveAnAccount: 'Non hai un account?',
  AlreadyHaveAnAccount: 'Hai già un account?',
  FirstName: 'Nome di battesimo',
  LastName: 'Cognome',
  ChooseCountry: 'Scegli Paese',
  ChooseField: 'Scegli Campo',
  ChooseLineManager: 'Scegli Gestore di linea',
  PasswordIsRequired: "E 'richiesta la password",
  EmailIsRequired: "L'e-mail è richiesta",
  PrivacyPolicyShouldBeAccepted:
    "L'informativa sulla privacy dovrebbe essere accettata",
  PrivacyPolicy: 'Politica Sulla Riservatezza',
  PrivacyPolicyPopUp:
    "Accetto che l'acquisizione, la divulgazione e il download del materiale disponibile per qualsiasi motivo non sia autorizzato da Fidia, poiché si tratta di materiale formativo ad uso esclusivamente interno.",
  Accept: 'Accettare',
  InvalidLoginCredentials: 'Credenziali di autenticazione non valide',
}
