import { useSelector } from 'react-redux'
import english from './english.js'
import italian from './italian.js'
import { useCookies } from 'react-cookie'
import { useEffect, useState } from 'react'

export function T() {
  const [cookies, setCookie] = useCookies(['Language'])
  let languageSelected = cookies['Language']
  var country = useSelector((state) => state.country)
  if (languageSelected) country = languageSelected

  switch (country) {
    case 'Italy':
      return italian
    default:
      return english
  }
}
