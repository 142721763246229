import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { loginAPI } from '../globalState/actions/AuthActions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import Header from '../Components/Header'
import { useSnackbar } from 'notistack'
import { useCookies } from 'react-cookie'
import PrivacyPolicyPopup from '../Components/PrivacyPolicyPopup'
import { ReactComponent as FlagOfUK } from '../images/Flag_of_the_United_Kingdom.svg'
import { ReactComponent as FlagOfItaly } from '../images/Flag_of_Italy.svg'
import { T } from '../Languages/LanguageHandler'
export default function Login() {
  const dict = T()
  const [cookies, setCookie] = useCookies(['Privacy Policy', 'Language'])
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [privacyPolicy, setPrivacyPolicy] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const emailChange = (event) => {
    setEmail(event.target.value)
  }
  const passwordChange = (event) => {
    setPassword(event.target.value)
  }
  const privacyPolicyChange = (event) => {
    setPrivacyPolicy(event.target.checked)
  }

  const signIn = async (e) => {
    let errors = false
    if (!email) {
      enqueueSnackbar(dict['EmailIsRequired'], {
        variant: 'warning',
        autoHideDuration: 3000,
      })
      errors = true
    }
    if (!password) {
      enqueueSnackbar(dict['PasswordIsRequired'], {
        variant: 'warning',
        autoHideDuration: 3000,
      })
      errors = true
    }
    if (!privacyPolicy) {
      enqueueSnackbar(dict['PrivacyPolicyShouldBeAccepted'], {
        variant: 'warning',
        autoHideDuration: 3000,
      })
      errors = true
    }
    if (!errors) {
      let acceptedPolicyBefore = cookies['Privacy Policy']
      if (!acceptedPolicyBefore) {
        console.log('Showing Policy')
        setShowPopup(true)
      } else {
        console.log('Accepted Policy Before')
        logIn()
      }
    }
  }
  const goSignUp = () => {
    history.push('/signup')
  }
  const logIn = async () => {
    try {
      await dispatch(loginAPI(email, password, history))
    } catch (e) {
      enqueueSnackbar(dict['InvalidLoginCredentials'], {
        variant: 'warning',
        autoHideDuration: 3000,
      })
    }
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      signIn()
    }
  }
  const handleChangeLanguage = (language) => {
    setCookie('Language', language, { path: '/' })
  }
  return (
    <div>
      {/* <Header></Header> */}
      <PrivacyPolicyPopup
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        logIn={logIn}
        setCookie={setCookie}
      />
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td>
              <h1 className='display-3 LoginSignupHeader m-0'>
                {dict['Login']}
              </h1>
            </td>
          </tr>
          {/* <tr>
            <td
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '6%',
                color: '#083c64',
              }}
            >
              <h4>Language</h4>
            </td>
          </tr> */}
          <tr>
            <td
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '50px',
              }}
            >
              <FlagOfUK
                className={
                  cookies['Language'] === 'Global'
                    ? 'languageFlagsSelected'
                    : ''
                }
                style={{
                  width: '80px',
                  height: '64px',
                  marginLeft: '1%',
                  marginRight: '1%',
                  cursor: 'pointer',
                  filter: 'brightness(0.8)',
                }}
                onClick={() => handleChangeLanguage('Global')}
              ></FlagOfUK>
              <FlagOfItaly
                className={
                  cookies['Language'] === 'Italy' ? 'languageFlagsSelected' : ''
                }
                style={{
                  width: '80px',
                  height: '64px',
                  marginLeft: '1%',
                  marginRight: '1%',
                  cursor: 'pointer',
                  filter: 'brightness(0.8)',
                }}
                onClick={() => handleChangeLanguage('Italy')}
              ></FlagOfItaly>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '2%',
              }}
            >
              <Form
                className='responsiveLoginFormWidth'
                onKeyPress={handleKeyPress}
              >
                <Form.Group controlId='email'>
                  <Form.Control
                    className='LoginSignupFormControlResponsive'
                    onChange={emailChange}
                    type='email'
                    placeholder={dict['Email']}
                  />
                </Form.Group>
                <Form.Group controlId='password' style={{ marginTop: '20px' }}>
                  <Form.Control
                    onChange={passwordChange}
                    type='password'
                    placeholder={dict['Password']}
                    className='LoginSignupFormControlResponsive'
                  />
                </Form.Group>
                <Form.Group
                  controlId='privacyPolicy'
                  style={{ paddingLeft: '1%', marginTop: '20px' }}
                >
                  <Form.Check
                    onChange={privacyPolicyChange}
                    type='checkbox'
                    label={dict['PrivacyPolicyLogin']}
                  />
                </Form.Group>
              </Form>
            </td>
          </tr>
          <tr>
            <td style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={signIn} className='LoginSignupButton'>
                <h4>
                  {dict['Login']} {'>'}
                </h4>
              </Button>
            </td>
          </tr>
          <tr>
            <td
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '0.4%',
                marginBottom: '3%',
              }}
            >
              <h6
                style={{
                  fontWeight: '400',
                }}
              >
                {dict['DontHaveAnAccount']}
                <span onClick={goSignUp} className='hyperlinkText'>
                  {'  '}

                  {dict['Signup']}
                </span>{' '}
              </h6>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
