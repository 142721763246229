import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Header from '../Components/Header'
//import instructorPlaceholder from '../images/instructorPlaceholder.png'
import instructorPlaceholder from '../images/tempInstructor.jpg'
import axios from 'axios'
import { backendLink } from '../keys_dev'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import Button from '@restart/ui/esm/Button'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { ReactComponent as PlayIcon } from '../images/playCircle.svg'
// import StarRatings from 'react-star-ratings'
import ReactPlayer from 'react-player'
import Form from 'react-bootstrap/Form'
import { Document, Page, pdfjs } from 'react-pdf'
import { debounce } from 'debounce'
import SubmitAnswersPopup from '../Components/SubmitAnswersPopup'
import { T } from '../Languages/LanguageHandler'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function Course() {
  const dict = T()
  const userId = useSelector((state) => state.id)
  var pathArray = window.location.pathname.split('/')
  const [disableButton, setDisableButton] = useState(false)
  const [course, setCourse] = useState()
  const [sectionSelected, setSectionSelected] = useState()
  const [sectionSelectedIndex, setSectionSelectedIndex] = useState()
  const [courseData, setCourseData] = useState()
  const [instructor, setInstructor] = useState()
  const [score, setScore] = useState()
  const [maxScore, setMaxScore] = useState()
  const [show, setShow] = useState(false)
  const history = useHistory()
  const [questionState, setQuestionState] = useState()
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [disablePreButton, setDisablePreButton] = useState(false)
  const [disablePostButton, setDisablePostButton] = useState(false)

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  function handleResize() {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    })
  }

  window.addEventListener('resize', debounce(handleResize, 1000))

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const handlePopupClose = () => {
    setShow(false)
    console.log('SECTION TO SELECT', sectionSelectedIndex + 1)
    goToNextSection(sectionSelectedIndex + 1)
  }
  const goToNextSection = (i) => {
    if (course.sections.length > i) {
      const section = course.sections[i]
      setSectionSelected(section)
      setSectionSelectedIndex(i)
      setQuestionState(section.assessment.questions)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      window.location.reload()
    }
  }
  const handleAnswering = (e) => {
    e.preventDefault()
    var newQuestions = questionState.slice()
    newQuestions[parseInt(e.target.name)].answer = e.target.value
    console.log(newQuestions)
    setQuestionState(newQuestions)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setDisablePostButton(true)
    axios({
      url: `${backendLink}/api/v1/course/submitAnswers`,
      method: 'POST',
      data: {
        userId: userId,
        courseId: course._id,
        answers: questionState,
      },
    })
      .then((res) => {
        setScore(res.data.score)
        setMaxScore(res.data.maxScore)
        setShow(true)
        setDisablePostButton(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleSubmitPreAssessmentAnswers = (e) => {
    e.preventDefault()
    setDisablePreButton(true)
    axios({
      url: `${backendLink}/api/v1/course/submitPreAssessmentAnswers`,
      method: 'POST',
      data: {
        userId: userId,
        courseId: course._id,
        answers: questionState,
      },
    })
      .then((res) => {
        axios({
          url: `${backendLink}/api/v1/user/courseData`,
          method: 'POST',
          data: {
            courseId: pathArray[2],
            userId: userId,
          },
        })
          .then((res) => {
            if (res.data.success) {
              setCourseData(res.data.data)
              setDisablePreButton(false)
            }
          })
          .catch((err) => {
            console.log(err)
          })
        // window.location.reload()
        // setQuestionState(sectionSelected.assessment.questions)
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const navigate = (path) => {
    history.push(path)
  }

  useEffect(() => {
    axios({
      url: `${backendLink}/api/v1/course/${pathArray[2]}`,
      method: 'GET',
    })
      .then((res) => {
        setCourse(res.data.course)

        axios({
          url: `${backendLink}/api/v1/instructor/${res.data.course.instructorId}`,
          method: 'GET',
        })
          .then((res) => {
            setInstructor(res.data.instructor)
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
    axios({
      url: `${backendLink}/api/v1/user/courseData`,
      method: 'POST',
      data: {
        courseId: pathArray[2],
        userId: userId,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setCourseData(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [sectionSelectedIndex]) // eslint-disable-line react-hooks/exhaustive-deps

  const enrollCourse = () => {
    setDisableButton(true)
    axios({
      url: `${backendLink}/api/v1/user/courseEnroll/${userId}`,
      method: 'PUT',
      data: {
        courseId: pathArray[2],
      },
    })
      .then((res) => {
        if (res.data.success) {
          window.location.reload()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function timeInMinsToString(time) {
    var hours = Math.floor(time / 60)
    var minutes = time % 60
    var output = ''
    if (hours > 0) {
      output += hours + 'hr'
    }
    if (minutes > 0) {
      if (hours > 0) {
        output += ' '
      }
      output += minutes + 'min'
    }

    return output
  }

  const handleSectionSelect = (i) => {
    if (courseData && courseData.currentSection > i) {
      const section = course.sections[i]
      setSectionSelected(section)
      setSectionSelectedIndex(i)
      setQuestionState(section.assessment.questions)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
  function DisplaySections() {
    return course.sections.map((section, index) => (
      <tr
        key={section._id}
        onClick={() => handleSectionSelect(index)}
        className={
          courseData && courseData.currentSection <= index
            ? 'unavailableSection'
            : sectionSelected && sectionSelected._id === section._id
            ? 'selectedSection'
            : null
        }
      >
        <td>
          <Row
            style={{ paddingLeft: '3%', height: '100%', paddingRight: '2%' }}
          >
            <Col xs={6} sm={3} md={3} lg={3} xl={3} style={{ display: 'flex' }}>
              <div
                style={{ display: 'flex' }}
                className={
                  sectionSelected && sectionSelected._id === section._id
                    ? 'syllabusChapterNameSelected'
                    : 'syllabusChapterName'
                }
              >
                <PlayIcon style={{ width: '12%' }} />
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <h4
                    style={{
                      fontWeight: '400',
                      marginBottom: '0',
                      marginLeft: '2%',
                    }}
                  >
                    {section.name}
                  </h4>
                </div>
              </div>
            </Col>
            <Col
              xs={6}
              sm={{
                span: 6,
                offset: 3,
              }}
              md={{
                span: 6,
                offset: 3,
              }}
              lg={{
                span: 6,
                offset: 3,
              }}
              xl={{
                span: 3,
                offset: 6,
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div>
                <h5
                  style={{
                    fontWeight: '500',
                  }}
                  className={
                    sectionSelected && sectionSelected._id === section._id
                      ? 'courseTimeSyllabusSelected'
                      : 'courseTimeSyllabus'
                  }
                >
                  {timeInMinsToString(section.time)}
                </h5>
              </div>
            </Col>
          </Row>
        </td>
      </tr>
    ))
  }
  function SectionNotChosen() {
    return (
      <div>
        {/* HEADER WITH BREADCRUMBS */}
        <div className='CourseHeader'>
          <Row>
            <Col xs={10} md={6} lg={4} xl={4}>
              <div style={{ display: 'flex', marginLeft: '22%' }}>
                <h6
                  onClick={() => navigate('/courses')}
                  style={{
                    paddingRight: '0.7%',
                    cursor: 'pointer',
                    fontWeight: '400',
                  }}
                >
                  {dict['Browse']}
                </h6>
                <h6
                  style={{
                    paddingRight: '0.7%',
                    userSelect: 'none',
                    fontWeight: '400',
                  }}
                >
                  {'>'}
                </h6>
                {course ? (
                  <h6
                    onClick={() => navigate(`/courses/${course.field}`)}
                    style={{ cursor: 'pointer', fontWeight: '400' }}
                  >
                    {dict[course.field]}
                  </h6>
                ) : null}
              </div>
              <div style={{ marginLeft: '21%' }}>
                <h1 style={{ fontWeight: '500' }} className='display-4'>
                  {course ? course.name : null}
                </h1>
              </div>
              <div className='trainerResponsive'>
                <img
                  src={
                    instructor && instructor.photoLink
                      ? instructor.photoLink
                      : instructorPlaceholder
                  }
                  alt='Avatar'
                  className='instructorAvatar'
                ></img>
                <h5 style={{ textAlign: 'center' }}>
                  {instructor ? instructor.name : null}
                </h5>
                <h6
                  style={{
                    fontWeight: '300',
                    textAlign: 'center',
                  }}
                >
                  {instructor ? instructor.field : null}
                </h6>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {courseData ? (
                  <Button className='enrolledButton'>
                    <h5
                      style={{
                        verticalAlign: 'middle',
                        alignSelf: 'center',
                        fontWeight: '650',
                      }}
                    >
                      {dict['Enrolled']}
                    </h5>
                  </Button>
                ) : (
                  <Button
                    className='notEnrolledButton'
                    disabled={disableButton}
                    onClick={enrollCourse}
                  >
                    <h5
                      style={{
                        verticalAlign: 'middle',
                        alignSelf: 'center',
                        fontWeight: '650',
                      }}
                    >
                      {dict['Enroll now']}
                    </h5>
                  </Button>
                )}

                <h5 style={{ marginTop: '2%' }}>
                  {course ? course.enrolled.toLocaleString() : null}{' '}
                  {dict['already enrolled']}
                </h5>
              </div>
            </Col>
            <Col
              xs={5}
              sm={3}
              md={4}
              lg={{
                span: 3,
              }}
              xl={{
                span: 2,
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginBottom: '3%',
              }}
            >
              {courseData ? (
                <div style={{ width: '85%' }}>
                  <CircularProgressbarWithChildren
                    value={courseData.percentageDone}
                    strokeWidth='9'
                    styles={{
                      // Customize the root svg element
                      root: {},
                      // Customize the path, i.e. the "completed progress"
                      path: {
                        // Path color
                        stroke: `rgba(52, 199, 89, 1)`,
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Customize transition animation
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                      },
                      // Customize the circle behind the path, i.e. the "total progress"
                      trail: {
                        // Trail color
                        stroke: '#d6d6d6',
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                      },
                      // Customize the text
                      text: {
                        // Text color
                        fill: '#d6d6d6',
                      },
                    }}
                  >
                    <h3>{dict['Progress']}</h3>
                    <h3>{courseData.percentageDone.toFixed(2)}%</h3>
                  </CircularProgressbarWithChildren>
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
        {/* JUMPBAR */}
        <table style={{ width: '100%', marginBottom: '2%' }}>
          <tbody>
            <tr className='courseBarResponsive'>
              <td>
                <span className='chosenOptionCourseUnselectedResponsive'>
                  {dict['About']}
                </span>
                <span
                  style={{
                    borderLeft: '0.15vw solid #9e9e9e',
                    //   height: '1%',
                    width: '100%',
                    marginLeft: '0.5vw',
                    marginRight: '0.5vw',
                  }}
                ></span>
                <span className='chosenOptionCourseUnselectedResponsive'>
                  {dict['Scores']}
                </span>
                <span
                  style={{
                    borderLeft: '0.15vw solid #9e9e9e',
                    height: '1.8vw',
                    width: '0.5vw',
                    marginLeft: '0.5vw',
                    marginRight: '0.5vw',
                  }}
                ></span>
                <span className='chosenOptionCourseUnselectedResponsive'>
                  {dict['Syllabus']}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className='paddingInlineResponsive'>
          {/* ABOUT */}
          <div>
            <h1 className='courseAboutTitle display-2'>
              {dict['About this Course']}
            </h1>

            <h5 className='courseAboutDescription'>
              {course ? course.description : null}
            </h5>
          </div>
          {/* INSTRUCTOR */}
          <div style={{ marginTop: '7%' }}>
            <h1 className='courseAboutTitle display-5'>{dict['Instructor']}</h1>
            <div style={{ display: 'flex' }}>
              <img
                src={
                  instructor && instructor.photoLink
                    ? instructor.photoLink
                    : instructorPlaceholder
                }
                alt='Avatar'
                className='instructorAvatar'
                style={{
                  width: `calc(3.5rem + 1.5vw)`,
                  height: '100%',
                  marginLeft: '0',
                  marginTop: '1%',
                }}
              ></img>
              <table
                style={{
                  marginTop: '0.5%',
                  marginLeft: '2%',
                  height: '100%',
                }}
              >
                <tbody>
                  {/* <tr style={{ height: '1vw' }}>
                    <td
                      className='courseCardField'
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        paddingLeft: '0vw',
                      }}
                    >
                      <div style={{ width: '7vw' }}>
                        <StarRatings
                          rating={2.7}
                          starRatedColor='#e5c100 '
                          numberOfStars={5}
                          name='courseRating'
                          starDimension='0.9vw'
                          starSpacing='0vw'
                        />
                      </div>
                      <div
                        className='courseCardField'
                        style={{
                          paddingLeft: '0vw',
                          paddingTop: '0.3vw',
                          fontSize: '0.74vw',
                        }}
                      >
                        (15,250)
                      </div>
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      <h1 className='courseInstructorName'>
                        {instructor ? instructor.name : null}
                      </h1>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className='courseAboutDescription'>
                        Lorem ipsum dolor sit amet, consetetur.
                      </h6>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function TOF(props) {
    return (
      <div style={{ marginTop: '5%' }}>
        <h2 className='courseInstructorName'>
          {dict['Question']} {props.index + 1}:
        </h2>
        <h4 className='questionText'>{props.question.questionText}</h4>
        <Form style={{ marginTop: '2%', marginLeft: '1%' }}>
          <Form.Check
            checked={
              questionState[props.index].answer === 'true' ? true : false
            }
            type='radio'
            key='true'
            label={dict['True']}
            value='true'
            name={props.index}
            id={props.question._id}
            className='questionText h4'
            onChange={handleAnswering}
          />
          <Form.Check
            checked={
              questionState[props.index].answer === 'false' ? true : false
            }
            type='radio'
            label={dict['False']}
            key='false'
            value='false'
            name={props.index}
            id={props.question._id}
            className='questionText h4'
            onChange={handleAnswering}
          />
        </Form>
      </div>
    )
  }

  function MCQOptions(props) {
    return props.question.mcqOptions
      .split(',')
      .map((option, index) => (
        <Form.Check
          checked={questionState[props.index].answer === option ? true : false}
          type='radio'
          label={option}
          name={props.index}
          value={option}
          id={props.question._id}
          className='questionText h4'
          key={index}
          onChange={handleAnswering}
        />
      ))
  }

  const leftPageButton = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }
  const rightPageButton = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  function postAssessmentTaken() {
    let taken = false
    if (courseData) {
      courseData.answeredPreQuestions.forEach((question) => {
        if (question.sectionIndex === sectionSelectedIndex) {
          taken = true
        }
      })
      if (taken) {
        return true
      } else {
        return false
      }
    }
    return false
  }
  return (
    <div className='noOverflow'>
      <Header></Header>
      {sectionSelected ? (
        <SubmitAnswersPopup
          questions={sectionSelected.assessment.questions}
          score={score}
          maxScore={maxScore}
          show={show}
          handlePopupClose={handlePopupClose}
        />
      ) : null}

      {/* PRE ASSESSMENT CONDITIONAL */}
      {sectionSelected &&
      courseData &&
      sectionSelected.assessment.position === 'prepost' &&
      !postAssessmentTaken() ? (
        //start
        <div
          style={{ marginTop: '3%', paddingRight: '0' }}
          className='paddingInlineResponsive'
        >
          <h1>{sectionSelected.name} </h1>
          <h5 className='courseAboutDescription'>
            {sectionSelected.description}
          </h5>
          {sectionSelected.assessment &&
          courseData.currentSection === sectionSelectedIndex + 1 &&
          !courseData.completed ? (
            <div style={{ marginTop: '3%' }}>
              <h1 className='courseAboutTitle display-5'>
                {dict['Pre chapter assessment']}
              </h1>
              <h5
                className='courseAboutDescription'
                style={{ paddingLeft: '0.5%' }}
              >
                {
                  dict[
                    'Answer the following questions before proceeding to the chapter content.'
                  ]
                }
              </h5>
              {sectionSelected
                ? sectionSelected.assessment.questions.map((question, index) =>
                    question.type === 'shortQuestion' ? (
                      <div key={index} style={{ marginTop: '5%' }}>
                        <h2 className='courseInstructorName'>
                          {dict['Question']} {index + 1}:
                        </h2>
                        <h4 className='questionText'>
                          {question.questionText}
                        </h4>
                        <Form style={{ marginTop: '2%' }}>
                          <Form.Control
                            key={question._id}
                            name={index}
                            className='shortQuestion h6'
                            value={questionState[index].answer || ''}
                            onChange={handleAnswering}
                          />
                        </Form>
                      </div>
                    ) : question.type === 'mcq' ? (
                      <div key={index} style={{ marginTop: '5%' }}>
                        <h2 className='courseInstructorName'>
                          {dict['Question']} {index + 1}:
                        </h2>
                        <h4 className='questionText'>
                          {question.questionText}
                        </h4>
                        <Form style={{ marginTop: '2%', marginLeft: '1%' }}>
                          <MCQOptions
                            question={question}
                            index={index}
                          ></MCQOptions>
                        </Form>
                      </div>
                    ) : (
                      <TOF key={index} question={question} index={index} />
                    )
                  )
                : null}

              <Button
                onClick={handleSubmitPreAssessmentAnswers}
                className='CourseSubmitAnswersButton h4'
                disabled={disablePreButton}
              >
                {dict['Submit']} {'>'}
              </Button>
            </div>
          ) : null}
        </div>
      ) : (
        //end
        <div>
          {sectionSelected ? (
            sectionSelected.contentPath.includes('mp4') ||
            sectionSelected.contentPath.includes('youtube') ? (
              // <ReactPlayer
              //   url={sectionSelected.contentPath}
              //   controls={true}
              //   width='100%'
              //   height='100%'
              // />

              <div className='player-wrapper'>
                <ReactPlayer
                  // Disable download button
                  config={{
                    file: { attributes: { controlsList: 'nodownload' } },
                  }}
                  // Disable right click
                  onContextMenu={(e) => e.preventDefault()}
                  className='react-player'
                  url={sectionSelected.contentPath}
                  controls={true}
                  width='100%'
                  height='85%'
                />
              </div>
            ) : (
              <div
                className='PDFWrapper'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Document
                  file={sectionSelected.contentPath}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    height={dimensions.width * 0.45}
                    pageNumber={pageNumber}
                  />
                </Document>
                <div className='page-controls'>
                  <button
                    onClick={leftPageButton}
                    disabled={pageNumber === 1 ? true : false}
                  >
                    ‹
                  </button>
                  <span>
                    Page {pageNumber} of {numPages}
                  </span>
                  <button
                    onClick={rightPageButton}
                    disabled={pageNumber === numPages ? true : false}
                  >
                    ›
                  </button>
                </div>
              </div>
            )
          ) : (
            <SectionNotChosen />
          )}
          {/* POST ASSESSMENT */}
          {sectionSelected ? (
            <div
              style={{ paddingRight: '0' }}
              className='paddingInlineResponsive'
            >
              <h1>{sectionSelected.name} </h1>
              <h5
                className='courseAboutDescription'
                style={{ paddingLeft: '0.5%' }}
              >
                {sectionSelected.description}
              </h5>
              {console.log(courseData.completed)}
              {sectionSelected.assessment &&
              courseData.currentSection === sectionSelectedIndex + 1 &&
              !courseData.completed ? (
                <div style={{ marginTop: '3%' }}>
                  <h1 className='courseAboutTitle display-5'>
                    {dict['Chapter assessment']}
                  </h1>
                  <h5 className='courseAboutDescription'>
                    {
                      dict[
                        'Answer the following questions to proceed to the next chapter.'
                      ]
                    }
                  </h5>

                  {sectionSelected
                    ? sectionSelected.assessment.questions.map(
                        (question, index) =>
                          question.type === 'shortQuestion' ? (
                            <div key={index} style={{ marginTop: '5%' }}>
                              <h2 className='courseInstructorName'>
                                {dict['Question']} {index + 1}:
                              </h2>
                              <h4 className='questionText'>
                                {question.questionText}
                              </h4>
                              <Form style={{ marginTop: '2%' }}>
                                <Form.Control
                                  key={question._id}
                                  name={index}
                                  className='shortQuestion'
                                  value={questionState[index].answer || ''}
                                  onChange={handleAnswering}
                                />
                              </Form>
                            </div>
                          ) : question.type === 'mcq' ? (
                            <div key={index} style={{ marginTop: '5%' }}>
                              <h2 className='courseInstructorName'>
                                {dict['Question']} {index + 1}:
                              </h2>
                              <h4 className='questionText'>
                                {question.questionText}
                              </h4>
                              <Form
                                style={{ marginTop: '2%', marginLeft: '1%' }}
                              >
                                <MCQOptions
                                  question={question}
                                  index={index}
                                ></MCQOptions>
                              </Form>
                            </div>
                          ) : (
                            <TOF
                              key={index}
                              question={question}
                              index={index}
                            />
                          )
                      )
                    : null}

                  <Button
                    onClick={handleSubmit}
                    className='CourseSubmitAnswersButton h4'
                    disabled={disablePostButton}
                  >
                    {dict['Submit']} {'>'}
                  </Button>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )}

      {/* SYLLABUS */}
      <div
        className='paddingInlineResponsive'
        style={{ marginTop: '5%', marginBottom: '5%' }}
      >
        <h1 className='courseAboutTitle display-5'>{dict['Syllabus']}</h1>
        {course && course.sections.length > 0 ? (
          <div className='syllabusTableWrapper'>
            <table className='syllabusTable'>
              <tbody>
                <DisplaySections></DisplaySections>
              </tbody>
            </table>
          </div>
        ) : (
          <h5 className='courseAboutDescription'>
            {dict['This course has no chapters yet.']}
          </h5>
        )}
      </div>
    </div>
  )
}
