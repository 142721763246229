import './App.css'
import './Components/Header'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import { useSelector } from 'react-redux'

import Login from './Pages/Login'
import Signup from './Pages/Signup'
import MyProfile from './Pages/MyProfile'
import Courses from './Pages/Courses'
import Home from './Pages/Home'
import Course from './Pages/Course'
import Uploader from './Components/Uploader'
import ControlPanel from './Pages/ControlPanel'
import Analytics from './Pages/Analytics'
import { SnackbarProvider } from 'notistack'

function App() {
  const token = useSelector((state) => state.token)
  const type = useSelector((state) => state.type)
  return (
    <SnackbarProvider maxSnack={10}>
      <Router>
        <Switch>
          <Route
            exact
            path='/'
            render={() =>
              token ? <Redirect to='/home' /> : <Redirect to='/login' />
            }
          />
          <Route
            exact
            path='/login'
            render={() => (token ? <Redirect to='/home' /> : <Login />)}
          />
          <Route
            exact
            path='/signup'
            render={() => (token ? <Redirect to='/myprofile' /> : <Signup />)}
          />
          <Route
            exact
            path='/myprofile'
            render={() =>
              token ? (
                type === 'admin' ? (
                  <Redirect to='/cp' />
                ) : (
                  <MyProfile />
                )
              ) : (
                <Redirect to='/login' />
              )
            }
          />
          <Route
            exact
            path='/courses/:filter'
            render={() =>
              token ? (
                type === 'admin' ? (
                  <Redirect to='/cp' />
                ) : (
                  <Courses />
                )
              ) : (
                <Redirect to='/login' />
              )
            }
          />
          <Route
            exact
            path='/courses'
            render={() =>
              token ? (
                type === 'admin' ? (
                  <Redirect to='/cp' />
                ) : (
                  <Courses />
                )
              ) : (
                <Redirect to='/login' />
              )
            }
          />
          <Route
            exact
            path='/home'
            render={() =>
              token ? (
                type === 'admin' ? (
                  <Redirect to='/cp' />
                ) : (
                  <Home />
                )
              ) : (
                <Redirect to='/login' />
              )
            }
          />
          <Route
            exact
            path='/course/:id'
            render={() =>
              token ? (
                type === 'admin' ? (
                  <Redirect to='/cp' />
                ) : (
                  <Course />
                )
              ) : (
                <Redirect to='/login' />
              )
            }
          />
          <Route
            exact
            path='/upload'
            render={() => (token ? <Uploader /> : <Redirect to='/login' />)}
          />
          <Route
            exact
            path='/cp'
            render={() =>
              token && type === 'admin' ? (
                <ControlPanel />
              ) : (
                <Redirect to='/login' />
              )
            }
          />
          {/* <Route
            exact
            path='/analytics/:type'
            render={() =>
              token && type === 'admin' ? (
                <Analytics />
              ) : (
                <Redirect to='/login' />
              )
            }
          /> */}
          <Route
            exact
            path={[
              '/analytics/:type/:subtype',
              '/analytics/:type',
              '/analytics',
            ]}
            render={() =>
              token && type === 'admin' ? (
                <Analytics />
              ) : (
                <Redirect to='/login' />
              )
            }
          />
        </Switch>
      </Router>
    </SnackbarProvider>
  )
}

export default App
