import React from 'react'
import coursePlaceholder from '../images/coursePlaceholder.png'
// import StarRatings from 'react-star-ratings'
import { useHistory } from 'react-router'
import { T } from '../Languages/LanguageHandler'

export default function CourseCard(props) {
  const dict = T()
  let courseId = props.courseId
  const history = useHistory()

  const redirectToCourse = () => {
    history.push(`/course/${courseId}`)
  }

  return (
    <div className='courseCardWrapper' onClick={redirectToCourse}>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td colSpan='2'>
              <img
                src={coursePlaceholder}
                alt='coursePlaceholder'
                style={{ width: '100%' }}
              ></img>
            </td>
          </tr>
          {/* <tr style={{ height: '3vw' }}> */}
          <tr style={{ height: '70px' }}>
            <td className='courseCardField'>
              <h6>
                <span>{dict[props.courseField]}</span>
                {props.courseSubField && (
                  <span> - {dict[props.courseSubField]}</span>
                )}
              </h6>
            </td>
            <td>
              <div
                className='courseCardTime'
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <h6> {Math.trunc(props.courseMinsTaken / 60)}h</h6>
              </div>
            </td>
          </tr>
          <tr style={{ height: '40px' }}>
            <td className='courseCardName'>
              <h5>{props.courseName}</h5>
            </td>
          </tr>
          <tr style={{ height: '40px' }}>
            {/* <td
                className='courseCardField'
              style={{ display: 'flex', justifyContent: 'start', width: '70%' }}
            >
              <div style={{ width: '80%', paddingTop: '7%' }}>
                <StarRatings
                  rating={2.7}
                  starRatedColor='#e5c100 '
                  numberOfStars={5}
                  name='courseRating'
                  // starDimension='1vw'
                  starDimension='20%'
                  starSpacing='0'
                />
              </div>
              <div
                className='courseCardField'
                style={{
                  paddingLeft: '0vw',
                  paddingTop: '10%',
                }}
              >
                <h6>(15,250)</h6>
              </div>
            </td> */}
            <td colSpan='2'>
              <div className='courseCardViews'>
                <h6>
                  {props.courseViews.toLocaleString()} {dict['Views']}
                </h6>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
